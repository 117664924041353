export const SLIDER_SKELETON = [{
    name: 'BaseSlider',
    type: 'div',
    isLoopParent: true,
    children: [
        { name: 'Slide', type: 'div' }
    ]
}];

export const TRUE_STRING = 'true';
export const DEFAULT_AUTOPLAY_VALUE = 5000;
export const DIRECTION_RIGHT = 'right';
export const DEFAULT_VISIBLE_SLIDES = 1;
export const SLIDER_DISABLED_DESKTOP_CLASS = 'pagebuilder-slider-disabled-desktop';
