/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Edgars Kuzmanis <info@scandiweb.com>
 */

// import FormPortal from 'Component/FormPortal';

import PrivacyConsentCheckboxes from '../component/PrivacyConsentCheckboxes';
import { AREA_REGISTRATION } from '../util/Privacy';

const updateSelection = (selection, isSatisfied, instance) => {
    const { updatePrivacyConsentSelection } = instance.props;

    updatePrivacyConsentSelection(selection, isSatisfied);
};

const renderPrivacyConsent = (instance) => (
    <PrivacyConsentCheckboxes
      area={ AREA_REGISTRATION }
      // eslint-disable-next-line react/jsx-no-bind
      updateSelection={ (selection, isSatisfied) => updateSelection(selection, isSatisfied, instance) }
    />
);

export const render = (args, callback, instance) => {
    const { formId, isCreateUser } = instance.props;

    return (
        <div
          block="CheckoutGuestForm"
          mix={ { block: 'FieldForm' } }
        >
            { instance.renderHeading() }
            <FormPortal
              id={ formId }
              name="CheckoutGuestForm"
            >
                { instance.renderFields() }
                { isCreateUser && renderPrivacyConsent(instance) }
                { instance.renderCreateUserCheckbox() }
            </FormPortal>
        </div>
    );
};

export default {};
