/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PAYPAL_EXPRESS, PAYPAL_EXPRESS_CREDIT } from '../component/PayPal/PayPal.config';
import { lazy } from 'react';

const PayPal = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout" */
    '../component/PayPal'
));

export const renderPayPal = (instance) => {
    const {
        selectedPaymentCode,
        setLoading,
        setDetailsStep,
        subscribeNewsletterOnSubmit,
        saveDuplicateOrdersInfo,
        getPayPalPaymentData,
        handleBillingSubmit
    } = instance.props;

    return (
        <PayPal
          setLoading={ setLoading }
          setDetailsStep={ setDetailsStep }
          selectedPaymentCode={ selectedPaymentCode }
          subscribeNewsletterOnSubmit={ subscribeNewsletterOnSubmit }
          saveDuplicateOrdersInfo={ saveDuplicateOrdersInfo }
          getPayPalPaymentData={ getPayPalPaymentData }
          handleBillingSubmit={ handleBillingSubmit }
        />
    );
};

export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [PAYPAL_EXPRESS_CREDIT]: instance.renderNotSupported.bind(instance),
    [PAYPAL_EXPRESS]: () => renderPayPal(instance)
});

export const componentDidMount = (args, callback, instance) => {
    const { selectedPaymentCode, setOrderButtonEnableStatus } = instance.props;

    callback(...args);

    if (selectedPaymentCode === PAYPAL_EXPRESS) {
        setOrderButtonEnableStatus(false);
    }
};

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            componentDidMount
        },
        'member-property': {
            paymentRenderMap
        }
    }
};

export default config;
