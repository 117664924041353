/**
 * @category  Scandiweb
 * @package   ScandiPWA/AdenAnais
 * @author    Anastasija Zubkova <info@scandiweb.com>
 */

import { PropTypes } from 'prop-types';

export const EleType = PropTypes.shape({
    $$typeof: PropTypes.symbol,
    render: PropTypes.func
});

export const AccordionElementType = PropTypes.shape({
    Ele: EleType,
    propsBag: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ])
        )
    ),
    childData: PropTypes.arrayOf(PropTypes.string),
    childEleBag: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array
        ])
    )
});

export const AccordionType = PropTypes.shape({
    BaseAccordion: AccordionElementType,
    ExpandableContent: AccordionElementType
});
