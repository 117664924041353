/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AMSInfoBlockComponent from './AMSInfoBlock.component';

/** @namespace PageBuilder/Component/AMSInfoBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PageBuilder/Component/AMSInfoBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PageBuilder/Component/AMSInfoBlock/Container/AMSInfoBlockContainer */
export class AMSInfoBlockContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        setSelectedType: this.setSelectedType.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedType: -1
        };
    }

    containerProps() {
        const { element, isMobile } = this.props;
        const {
            selectedType
        } = this.state;

        return {
            element,
            isMobile,
            selectedType
        };
    }

    setSelectedType(value) {
        this.setState((prevState) => ({ ...prevState, selectedType: value }));
    }

    render() {
        return (
            <AMSInfoBlockComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AMSInfoBlockContainer);
