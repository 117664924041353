import Html from 'SourceComponent/Html';

/** @namespace PageBuilder/Component/DynamicBlock/Component/DynamicBlockComponent */
export class DynamicBlockComponent extends React.PureComponent {
    __construct(props) {
        super.__construct(props);
    }

    render() {
        const {
            elements: {
                BaseDynamicBlock,
                BannerWidget,
                BannerItems,
                BannerItem,
                BannerItemContent
            } = {},
            content
        } = this.props;

        if (!content) {
            return null;
        }

        return (
          <BaseDynamicBlock.Ele>
              <BannerWidget.Ele>
                  <BannerItems.Ele>
                      { BannerItem.propsBag.map((props, idx) => (
                          <BannerItem.Ele { ...props } key={ `banner-item-${idx}` }>
                              <BannerItemContent.Ele { ...BannerItemContent.propsBag[idx] }>
                                  <Html content={ content } />
                              </BannerItemContent.Ele>
                          </BannerItem.Ele>
                      )) }
                  </BannerItems.Ele>
              </BannerWidget.Ele>
          </BaseDynamicBlock.Ele>
        );
    }
}

export default DynamicBlockComponent;
