import { lazy } from 'react';

const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product" */
    '../component/ProductLabel'
));

/** @namespace ProductLabel/Plugin/ProductCard/Plugin/ProductCardPlugin */
export class ProductCardPlugin {
    renderProductLabels(instance) {
        const {
            product: {
                mp_label_data = [],
                price_range: {
                    minimum_price = {}
                } = {}
            },
            isCarouselItem,
            isWidgetItem
        } = instance.props;

        if (isCarouselItem || isWidgetItem) {
            return null;
        }

        return (
            <ProductLabel
              labelsData={ mp_label_data }
              minPriceRange={ minimum_price }
              isPLP={ true }
            />
        );
    }

    renderCardContent = (args, callback, instance) => {
        const {
            isPLP, product: {
                mp_label_data = []
            }
        } = instance.props;

        if (isPLP) {
            return (
                <>
                    { instance.renderCardLinkWrapper((
                        <>
                            <div block="ProductCard" elem="FigureReview">
                                <figure block="ProductCard" elem="Figure">
                                    { mp_label_data.length > 0 && this.renderProductLabels(instance) }
                                    { instance.renderPicture() }
                                </figure>
                            </div>
                            <div block="ProductCard" elem="Content">
                                { instance.renderMainDetails() }
                                { instance.renderHairColourSwatches() }
                                <div block="ProductCard" elem="PriceAndWishlistButton">
                                    { instance.renderProductPrice() }
                                    <div block="ProductCard" elem="WishListButtonContainer">
                                        { instance.renderProductCardWishlistButton() }
                                    </div>
                                </div>
                            </div>
                        </>
                    )) }
                    { instance.renderAddToCart() }
                </>
            );
        }

        return callback(...args);
    }
}

const { renderCardContent } = new ProductCardPlugin();

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderCardContent: {
                position: 100,
                implementation: renderCardContent
            }
        }
    }
};
