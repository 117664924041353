/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/zendesk
 * @author  Jort Geurts <info@scandiweb.com>
 */
import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import ZendeskLiveChatContainer from '../component/ZendeskLiveChat';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

const addZendeskChatComponent = (member, _context) => {
    const maxPosition = Math.max(
        ...member.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <ZendeskLiveChatContainer />,
            position: maxPosition + INCREASED_POSITION
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addZendeskChatComponent
        }
    }
};
