/** @namespace ProductPage/Plugin/ProductPage/Plugin/ProductPagePlugin */
export class ProductPagePlugin {
    aroundGetActiveProductDataSource = (args, callback, instance) => {
        const instanceData = callback(...args);

        const { activeProduct } = instance.state;
        const product = instance.getDataSource();

        if (!activeProduct || !product) {
            return product;
        }

        const {
            mp_label_data: productLabelData = []
        } = product;

        const {
            mp_label_data: activeProductLabelData = []
        } = activeProduct;

        if (!activeProductLabelData.length && !productLabelData.length) {
            return instanceData;
        }

        return {
            ...instanceData,
            mp_label_data: activeProductLabelData.length === 0 ? [...productLabelData] : [...activeProductLabelData]
        };
    };
}

const { aroundGetActiveProductDataSource } = new ProductPagePlugin();

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            getActiveProductDataSource: {
                position: 100,
                implementation: aroundGetActiveProductDataSource
            }
        }
    }
};
