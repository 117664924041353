import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace PageBuilder/Component/Tab/Component/TabComponent */
export class TabComponent extends PureComponent {
    static propTypes = {
        onClickTabMenu: PropTypes.func.isRequired,
        activeIdx: PropTypes.number.isRequired,
        elements: PropTypes.string.isRequired
    };

    renderTabContent(TabItem, activeIdx, TabContent) {
        const {
            propsBag,
            childEleBag
        } = TabItem;

        return (
            <TabContent.Ele>
                { propsBag.map((props, idx) => activeIdx === idx
                    && (
                        <TabItem.Ele
                            key={ `tab-content-item-${propsBag[idx].id}` }
                        >
                            { childEleBag[idx] }
                        </TabItem.Ele>
                    )) }
            </TabContent.Ele>
        );
    }

    renderTabMenu(TabMenuHeader, activeIdx, TabMenuLink, TabMenuTitle, TabMenu) {
        const { onClickTabMenu } = this.props;
        const { propsBag } = TabMenuHeader;

        return (
            <TabMenu.Ele
                className="tabs-navigation ui-tabs-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all"
            >
                { propsBag.map((props, idx) => (
                    <TabMenuHeader.Ele
                        key={ `tab-menu-${propsBag[idx]['data-pb-style']}` }
                        className={ classNames('tab-header ui-state-default ui-corner-top',
                            { 'ui-tabs-active ui-state-active': activeIdx === idx }) }
                        onClick={ (e) => onClickTabMenu(e, idx) }
                    >
                        <TabMenuLink.Ele href={ TabMenuLink.propsBag[idx].href } className="tab-title">
                            <TabMenuTitle.Ele>{ TabMenuTitle.childData[idx] }</TabMenuTitle.Ele>
                        </TabMenuLink.Ele>
                    </TabMenuHeader.Ele>
                )) }
            </TabMenu.Ele>
        );
    }

    render() {
        const {
            elements: {
                BaseTabs,
                TabMenu,
                TabMenuHeader,
                TabMenuLink,
                TabMenuTitle,
                TabContent,
                TabItem
            } = {},
            activeIdx
        } = this.props;

        return (
            <BaseTabs.Ele>
                { this.renderTabMenu(TabMenuHeader, activeIdx, TabMenuLink, TabMenuTitle, TabMenu) }
                { this.renderTabContent(TabItem, activeIdx, TabContent) }
            </BaseTabs.Ele>
        );
    }
}

export default TabComponent;
