/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PrivacyPolicyPopup from '../component/PrivacyPolicyPopup';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const beforeItems = (originalMember) => [
    ...originalMember,
    {
        component: <PrivacyPolicyPopup />,
        position: 9999
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE: beforeItems
        }
    }
};
