/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Store Finder Query
 * @class StoreFinderQuery
 * @namespace StoreFinder/Query/StoreFinder/Query/StoreFinderQuery
 */
export class StoreFinderQuery {
    /**
     * get Stores query
     * @return {Query} Store finder query
     * @memberof StoreFinderQuery
     */
    getQuery() {
        return new Field('getStores')
            .addFieldList([
                'store_name',
                'address',
                'city',
                'phone_number',
                'latitude',
                'longitude',
                'store_hours',
                'working_days',
                'is_active',
                'image',
                'identifier',
                'store_type'
            ]);
    }

    getSalonLocatorMetaData() {
        return new Field('metaData')
            .addFieldList([
                'meta_title',
                'meta_description'
            ]);
    }

    getApproximateStores(userLat, userLon, count) {
        return new Field('getApproximateStores')
            .addArgument('userLat', 'Float!', userLat)
            .addArgument('userLon', 'Float!', userLon)
            .addArgument('count', 'Int!', count)
            .addFieldList(this._getStoresFields());
    }

    _getStoresFields() {
        return [
            'store_name',
            'address',
            'city',
            'phone_number',
            'latitude',
            'longitude',
            'store_hours',
            'working_days',
            'is_active',
            'image',
            'identifier',
            'store_type',
            'distance'
        ];
    }

    getStoresByZip(zip, country, count) {
        return new Field('getStoresByZip')
            .addArgument('zip', 'String!', zip)
            .addArgument('country', 'String!', country)
            .addArgument('count', 'Int!', count)
            .addFieldList(this._getStoresFields());
    }
}

export default new (StoreFinderQuery)();
