/* eslint-disable no-param-reassign */

import FIELD_TYPE from '@scandipwa/scandipwa/src/component/Field/Field.config';
import { getRegionFields, getStreetFields, getVatFields }
from '@scandipwa/scandipwa/src/component/MyAccountAddressForm/MyAccountAddressForm.form';
import { VALIDATION_INPUT_TYPE } from '@scandipwa/scandipwa/src/util/Validator/Config';

/**
 * Returns address form fields
 * @param props
 * @param events
 * @returns {[{label: *, type: string, attr: {defaultChecked, name: string}}, {label: *, type: string, attr: {defaultChecked, name: string}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: string, name: string, placeholder: *}}, {mods: {street: boolean, multipleFields: boolean, oddAddresses: boolean}, name: string, fields: ({addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue: (*|string), name: string, placeholder: *}}[]|*[])}, null, ...*[]|{label: *, type: string, attr: {defaultValue: string, name: string}}[], null]}
 * @namespace Scandipwa/Component/CheckoutAddressForm/Form/CheckoutAddressForm */
export const CheckoutAddressForm = (props, events = {}) => {
    const {
        default_billing: defaultBilling,
        default_shipping: defaultShipping,
        countryId,
        firstname = '',
        lastname = '',
        city = '',
        countries = [],
        postcode: zipcode = '',
        telephone = '',
        addressLinesQty = 1,
        currentCity,
        currentZipcode,
        id
    } = props;

    const {
        onCountryChange,
        onZipcodeChange,
        onCityChange
    } = events || {};

    const config = [
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Billing Address'),
            attr: {
                name: 'default_billing',
                defaultChecked: defaultBilling
            }
        },
        {
            type: FIELD_TYPE.checkbox,
            label: __('This is default Shipping Address'),
            attr: {
                name: 'default_shipping',
                defaultChecked: defaultShipping
            }
        },
        {
            type: FIELD_TYPE.text,
            label: __('First name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname,
                placeholder: __('Your first name')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            }
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last name'),
            attr: {
                name: 'lastname',
                defaultValue: lastname,
                placeholder: __('Your last name')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            }
        },
        // streetGroup
        {
            name: 'streetGroup',
            mods: {
                street: true,
                multipleFields: addressLinesQty > 0,
                oddAddresses: addressLinesQty % 2 === 1
            },
            fields: getStreetFields(props)
        },
        // addressGroup
        {
            name: 'addressGroup',
            mods: { address: true },
            fields: [
                {
                    type: FIELD_TYPE.select,
                    label: __('Country'),
                    attr: {
                        id: 'address-country-id',
                        name: 'country_id',
                        defaultValue: countryId,
                        selectPlaceholder: __('Select country...')
                    },
                    events: {
                        onChange: onCountryChange
                    },
                    options: countries,
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true
                    }
                },
                ...getRegionFields(props, events),
                {
                    type: FIELD_TYPE.text,
                    label: __('Zip / Postal code'),
                    attr: {
                        name: 'postcode',
                        value: currentZipcode,
                        defaultValue: zipcode,
                        placeholder: __('Your zip / postal code')
                    },
                    events: {
                        onChange: onZipcodeChange
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange', 'onBlur'],
                    validationRule: {
                        isRequired: true
                    }
                },
                {
                    type: FIELD_TYPE.text,
                    label: __('City'),
                    attr: {
                        name: 'city',
                        value: currentCity,
                        defaultValue: city,
                        placeholder: __('Your city')
                    },
                    events: {
                        onChange: onCityChange
                    },
                    addRequiredTag: true,
                    validateOn: ['onChange'],
                    validationRule: {
                        isRequired: true
                    }
                }
            ]
        },
        ...getVatFields(props),
        {
            type: FIELD_TYPE.text,
            label: __('Phone number'),
            attr: {
                name: 'telephone',
                defaultValue: telephone,
                placeholder: __('Your phone number')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.phone,
                isRequired: true
            }
        }
    ];

    const updatedConfig = config.map((field) => {
        const clonedField = { ...field };

        if (clonedField.attr?.name === 'default_billing' || clonedField.attr?.name === 'default_shipping') {
            return clonedField;
        }

        if (clonedField.fields && Array.isArray(clonedField.fields)) {
            clonedField.fields = clonedField.fields.map((subField) => {
                const clonedSubField = { ...subField };
                clonedSubField.attr.name = `${ id }_${ clonedSubField.attr.name }`;
                clonedSubField.attr.id = clonedSubField.attr.name;

                return clonedSubField;
            });

            return clonedField;
        }

        clonedField.attr.name = `${ id }_${ clonedField.attr.name }`;
        clonedField.attr.id = clonedField.attr.name;

        return clonedField;
    });

    return updatedConfig;
};

export default CheckoutAddressForm;
