import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { cloneElement } from 'react';
import {
    EVENT_GTM_SITE_SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';

const state = (original) => ({
    ...original,
    lastSearch: ''
});

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const renderPage = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    const originalElement = callback(...args);

    return cloneElement(
        originalElement,
        { event }
    );
};

const renderNoProducts = (args, callback, instance) => {
    const { noResultsLoaded, resultsRequested } = instance.state;
    const { event } = instance.props;
    const { pathname } = window.location;
    const isSearch = pathname.includes(SEARCH);

    if (!isSearch) {
        return callback(...args);
    }

    const currentSearchQuery = pathname.split(ROOT).pop();

    if (noResultsLoaded) {
        const { lastSearch } = instance.state;

        if ((currentSearchQuery !== lastSearch) && resultsRequested) {
            event(EVENT_GTM_SITE_SEARCH, {
                products: 0, search: currentSearchQuery, list: SEARCH
            });
            instance.setState({ lastSearch: currentSearchQuery, noResultsLoaded: true, resultsRequested: false });
        }
    } else {
        if (resultsRequested) {
            event(EVENT_GTM_SITE_SEARCH, {
                products: 0, search: currentSearchQuery, list: SEARCH
            });
        }

        instance.setState({ lastSearch: currentSearchQuery, resultsRequested: false });
    }

    return callback(...args);
};

const render = (args, callback, instance) => {
    const {
        isLoading,
        isResultUpdating
    } = instance.props;

    if (isResultUpdating && isLoading) {
        instance.setState({ resultsRequested: true });
    }

    instance.setState({ noResultsLoaded: true });

    return callback(...args);
};

const containerProps = (args, callback, instance) => {
    const {
        event,
        isResultUpdating
    } = instance.props;

    return {
        ...callback(...args),
        event,
        isResultUpdating
    };
}

export default {
    'Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/ProductList/Component': {
        'member-function': {
            renderPage,
            renderNoProducts,
            render
        }
    }
};
