import {
    EVENT_GTM_SITE_SEARCH_STARTED
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const openSearch = (args, callback, instance) => {
    const { device: { isMobile }, onSearchBarFocus, event } = instance.props;

    onSearchBarFocus();

    if (!isMobile) {
        instance.onIconClick();
    }

    event(EVENT_GTM_SITE_SEARCH_STARTED, {});
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/SearchField/Component': {
        'member-function': {
            openSearch
        }
    },
    'Component/SearchField/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
