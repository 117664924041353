/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

// eslint-disable-next-line max-classes-per-file
import PropTypes from 'prop-types';
import { lazy } from 'react';

const MyAccountPrivacySettings = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    '../component/MyAccountPrivacySettings'
));

export const PRIVACY_SETTINGS = 'privacy-settings';

export class MyAccountPlugin {
    renderMap = (originalMember) => ({
        ...originalMember,
        [PRIVACY_SETTINGS]: MyAccountPrivacySettings
    });

    propTypes = (originalMember) => ({
        ...originalMember,
        activeTab: PropTypes.oneOfType([
            originalMember.activeTab,
            PropTypes.oneOf([PRIVACY_SETTINGS])
        ])
    });
}

// eslint-disable-next-line
export class MyAccountContainerPlugin {
    tabMap = (originalMember) => ({
        ...originalMember,
        [PRIVACY_SETTINGS]: {
            url: '/privacy-settings',
            tabName: __('Privacy Settings'),
            section: 1,
        }
    });
}

export const { renderMap, propTypes } = new MyAccountPlugin();
export const { tabMap } = new MyAccountContainerPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            renderMap
        },
        'static-member': {
            propTypes
        }
    },
    'Component/MyAccountTabList/Component': {
        'static-member': {
            propTypes
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap
        }
    }
};
