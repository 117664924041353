import { EVENT_GTM_PRODUCT_CLICK, EVENT_GTM_SITE_SEARCH } from '../component/GoogleTagManager/GoogleTagManager.events';
import { SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const handleItemClick = (args, callback, instance) => {
    const { event, position, product, searchCriteria = null} = instance.props;
    const { pathname } = window.location;
    const search = true;

    const productToPass = {
        ...product, position, pathname, search
    };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);

    if (searchCriteria) {
        event(EVENT_GTM_SITE_SEARCH, {
            products: 1, search: searchCriteria, list: SEARCH
        });
    }

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/SearchItem/Container': {
        'member-function': {
            handleItemClick
        }
    },
    'Component/SearchItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
