/**
 * @category  Scandiweb
 * @package   ScandiPWA/AdenAnais
 * @author    Anastasija Zubkova <info@scandiweb.com>
 */

export const ACCORDION_SKELETON = [{
    name: 'BaseAccordion',
    type: 'div',
    children: [
        { name: 'ExpandableContent', type: 'div' }
    ]
}];
