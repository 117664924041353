import {
    EVENT_GTM_CHECKOUT,
    EVENT_GTM_CHECKOUT_OPTION,
    EVENT_GTM_PURCHASE
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const GTM_CART_PAGE_STEP = 1;
export const GTM_SHIPPING_STEP = 2;
export const GTM_BILLING_STEP = 3;

export class GlobaleCheckoutPlugin {
    afterDestroyCheckout = (args, callback) => {
        callback(...args);

        /* eslint-disable no-undef */
        if (typeof GlobalE !== 'undefined' && GlobalE.OnClientEventCallback.length) {
            GlobalE.OnClientEventCallback.length = 0;
        }
        /* eslint-enable no-undef */
    };

    afterAttachCheckoutStepCallback = (args, callback, instance) => {
        callback(...args);

        const { event } = instance.props;

        // eslint-disable-next-line no-undef
        gle('OnClientEvent', (source, data) => {
            this.clientEventCallback(source, data, event);
        });
    };

    afterCheckoutStepCallback = (args, callback, instance) => {
        const [{
            StepId,
            Steps: { LOADED, CONFIRMATION } = {},
            IsSuccess: isSuccess = false,
            OrderId = 0,
            details
        }] = args;

        const stepMap = {
            [LOADED]: GTM_SHIPPING_STEP,
            [CONFIRMATION]: GTM_BILLING_STEP
        };

        const {
            event, totals: {
                items = [], discount_amount, coupon_code
            } = {}
        } = instance.props;

        const {
            customerTotalPrice,
            customerTotalVAT,
            customerDiscountedShippingPrice,
            customerTotalPriceInMerchantCurrency,
            customerDiscountedShippingPriceInMerchantCurrency,
            paymentMethodName,
            shippingServiceName,
            customerCurrency
        } = details;

        if (StepId === CONFIRMATION && isSuccess) {
            const timeout = setTimeout(() => {
                event(EVENT_GTM_PURCHASE, {
                    orderID: OrderId,
                    products: items,
                    revenue: customerTotalPrice,
                    order_total_gbp: customerTotalPriceInMerchantCurrency,
                    tax: customerTotalVAT,
                    shipping: customerDiscountedShippingPrice,
                    shipping_gbp: customerDiscountedShippingPriceInMerchantCurrency,
                    discount_amount,
                    coupon: coupon_code || '',
                    orderPaymentMethod: paymentMethodName,
                    orderShippingMethod: shippingServiceName,
                    currencyCode: customerCurrency
                });
                clearTimeout(timeout);
            }, 1);
        } else {
            event(EVENT_GTM_CHECKOUT, {
                step: stepMap[StepId] || StepId,
                isInternational: true
            });
        }
        callback(...args);
    };

    clientEventCallback(source, data, event) {
        const { name, id = '', price = '' } = data;

        if (!name) {
            return;
        }

        switch (source) {
        case GEMerchantUtils.ClientEvents.SHIPPINGMETHOD_SELECTION:
            event(EVENT_GTM_CHECKOUT_OPTION, { step: source, option: `${id} - ${price}` });
        case GEMerchantUtils.ClientEvents.PAYMENTMETHOD_CHANGED:
            event(EVENT_GTM_CHECKOUT_OPTION, { step: source, option: name });
            break;
        default:
            break;
        }
    }
}

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

const {
    afterAttachCheckoutStepCallback,
    afterDestroyCheckout,
    afterCheckoutStepCallback
} = new GlobaleCheckoutPlugin();

export default {
    'Globale/Component/GlobaleCheckout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Globale/Component/GlobaleCheckout/Container/GlobaleCheckoutContainer': {
        'member-function': {
            checkoutStepCallback: afterCheckoutStepCallback,
            containerProps
        }
    },
    'Globale/Component/GlobaleCheckout/Component/GlobaleCheckoutComponent': {
        'member-function': {
            attachCheckoutStepCallback: afterAttachCheckoutStepCallback,
            destroyCheckout: afterDestroyCheckout
        }
    }
};
