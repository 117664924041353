import Image from '@scandipwa/scandipwa/src/component/Image';
import PropTypes from 'prop-types';
import { TransformComponent } from 'react-zoom-pan-pinch';

export class ProductGalleryBaseImagePlugin {
    addLabelPropTypes = (args) => ({
        ...args,
        productLabels: PropTypes.arrayOf(PropTypes.shape({
            enabled: PropTypes.number,
            label: PropTypes.string,
            label_color: PropTypes.string,
            label_css: PropTypes.string,
            list_label: PropTypes.string,
            list_color: PropTypes.string,
            list_css: PropTypes.string,
            shipping_icon: PropTypes.number
        })),
        isImageZoomPopupActive: PropTypes.bool || null
    });

    addLabelDefaultProps = (args) => ({
        ...args,
        productLabels: [],
        product: {},
        isImageZoomPopupActive: null
    });

    containerProps = (args, callback, instance) => {
        const instanceProps = callback(...args);
        const { productLabels, product, isImageZoomPopupActive } = instance.props;

        return {
            ...instanceProps,
            productLabels,
            product,
            isImageZoomPopupActive
        };
    };

    render = (args, callback, instance) => {
        const {
            src, alt, productLabels, product, isImageZoomPopupActive
        } = instance.props;

        if (!productLabels || !productLabels.length) {
            return callback(...args);
        }

        return (
            <TransformComponent>
                <Image
                  src={ src }
                  ratio="custom"
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src }
                  } }
                  isPlaceholder={ !src }
                  alt={ alt }
                  productLabels={ productLabels }
                  product={ product }
                  isImageZoomPopupActive={ isImageZoomPopupActive }
                />
            </TransformComponent>
        );
    };
}

const {
    addLabelPropTypes, addLabelDefaultProps, containerProps, render
} = new ProductGalleryBaseImagePlugin();

export default {
    'Component/ProductGalleryBaseImage/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    },
    'Component/ProductGalleryBaseImage/Component': {
        'member-function': {
            render
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    }
};
