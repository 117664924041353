/* eslint-disable max-lines */
/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CarouselScrollArrow from 'Component/CarouselScrollArrow';
import Slider from 'Component/Slider';
import { RefType } from 'Type/Common.type';

import {
    BG_IMAGE_PATH,
    DIV_NAME,
    HEADER3_NAME,
    HEADER4_NAME,
    IMG_NAME,
    SPAN_NAME
} from './AMSSteps.config';

import './AMSSteps.style';

/** @namespace PageBuilder/Component/AMSSteps/Component/AMSStepsComponent */
export class AMSStepsComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired,
        selectedType: PropTypes.number.isRequired,
        setSelectedType: PropTypes.func.isRequired,
        selectedAttachment: PropTypes.number.isRequired,
        setSelectedAttachment: PropTypes.func.isRequired,
        setAttachmentsDetails: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        attachmentsDetails: PropTypes.array.isRequired,
        activeVideo: PropTypes.number.isRequired,
        sliderRef: RefType.isRequired
    };

    renderStepSubTitle(subTitle) {
        return domToReact([subTitle]);
    }

    renderFirstStepItems(items) {
        const filteredItems = items
            .filter(({ name }) => name === DIV_NAME)
            .map((item, index) => this.renderFirstStepItem(item, index));

        return (
            <div
              block="AMSSteps"
              elem="FirstStepOptions"
            >
                { filteredItems }
            </div>
        );
    }

    renderFirstStepItem(item, index) {
        const { setSelectedType, selectedType } = this.props;
        if (item?.name !== DIV_NAME) {
            return null;
        }

        return (
            <button
              block="AMSSteps"
              elem="FirstStepOption"
              mods={ { isSelected: selectedType === index } }
              onClick={ () => setSelectedType(index) }
            >
                { domToReact([item]) }
            </button>
        );
    }

    renderSecondStepItem(item, index) {
        const { setSelectedAttachment, selectedAttachment } = this.props;
        return (
            <button
              block="AMSSteps"
              elem="SecondStepOption"
              mods={ { isSelected: selectedAttachment === index } }
              onClick={ () => setSelectedAttachment(index) }
            >
                { domToReact([item]) }
            </button>
        );
    }

    renderSecondStepItems(items) {
        const {
            setAttachmentsDetails, attachmentsDetails, selectedAttachment, setSelectedAttachment, sliderRef
        } = this.props;

        const filteredItems = items
            .filter(({ name }) => name === DIV_NAME)
            .map((item) => item);

        const renderFilteredItems = items
            .filter(({ name }) => name === DIV_NAME)
            .map((item, index) => this.renderSecondStepItem(item, index));

        const attachmentsDetailsArray = filteredItems
            .map(({ children }) => children.filter(({ name }) => name === HEADER4_NAME))
            .map((child) => child.map(({ children }) => (children?.[0].data)));

        if (!attachmentsDetails.length) {
            setAttachmentsDetails(attachmentsDetailsArray);
        }

        return (
            <div
              block="AMSSteps"
              elem="SecondStepSlider"
            >
                <CarouselScrollArrow
                  isNextArrow
                  isInvisible={ false }
                  onClick={ setSelectedAttachment }
                />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'AMSSteps', elem: 'SecondStepSlider' } }
                  activeImage={ selectedAttachment }
                  onActiveImageChange={ setSelectedAttachment }
                  slidesQuantity={ filteredItems?.length }
                  showArrows
                >
                    { renderFilteredItems }
                </Slider>
                <CarouselScrollArrow
                  isNextArrow={ false }
                  isInvisible={ false }
                  onClick={ setSelectedAttachment }
                />
            </div>
        );
    }

    renderSelectedAttachmentDetails() {
        const { attachmentsDetails, selectedAttachment } = this.props;

        const title = attachmentsDetails?.[selectedAttachment]?.[0];
        const description = attachmentsDetails?.[selectedAttachment]?.[1];

        return (
            <div
              block="AMSSteps"
              elem="AttachmentDescription"
            >
                <h4>{ title }</h4>
                <p>{ description }</p>
            </div>
        );
    }

    renderDots(index) {
        const { selectedAttachment, setSelectedAttachment } = this.props;
        return (
            <button
              block="AMSSteps"
              elem="SecondStepDot"
              mods={ { isSelected: index === selectedAttachment } }
              onClick={ () => setSelectedAttachment(index) }
            />
        );
    }

    renderStepMainImage(element) {
        const location = window.location.origin;

        return (
            <div
              block="AMSSteps"
              elem="SecondStepMainImage"
              style={ { backgroundImage: `url(${location}${BG_IMAGE_PATH})` } }
            >
                { domToReact([element]) }
            </div>
        );
    }

    renderVideo(stepOptions) {
        const filteredVideos = stepOptions.filter(({ name }) => name === DIV_NAME).map((item) => item);
        return filteredVideos.map((video, index) => this.renderSelectedVideo(video, index));
    }

    renderSelectedVideo(video, index) {
        const { activeVideo } = this.props;
        if (index === activeVideo) {
            return domToReact([video]);
        }

        return null;
    }

    renderFirstStep() {
        const { element: { children } } = this.props;
        const { children: firstStepChildren } = children.find(
            (child) => child?.attribs?.['data-content-type'] === 'scandiweb_ams_step_1'
        );
        const stepTitle = firstStepChildren?.find(({ name }) => name === SPAN_NAME);
        const stepSubTitle = firstStepChildren?.find(({ name }) => name === HEADER3_NAME);
        const { children: stepOptions } = firstStepChildren?.find(({ name }) => name === DIV_NAME);

        return (
            <div
              block="AMSSteps"
              elem="FirstStepWrapper"
            >
                { domToReact([stepTitle]) }
                { this.renderStepSubTitle(stepSubTitle) }
                { this.renderFirstStepItems(stepOptions) }
            </div>
        );
    }

    renderSecondStep() {
        const { element: { children }, attachmentsDetails } = this.props;
        const { children: second } = children.find(
            (child) => child?.attribs?.['data-content-type'] === 'scandiweb_ams_step_2'
        );
        const stepSubTitle = second?.find(({ name }) => name === HEADER3_NAME);
        const { children: stepOptions } = second?.find(({ name }) => name === DIV_NAME);
        const stepMainImage = second?.find(({ name }) => name === IMG_NAME);

        return (
            <div
              block="AMSSteps"
              elem="SecondStepWrapper"
            >
                { this.renderStepSubTitle(stepSubTitle) }
                { this.renderSelectedAttachmentDetails() }
                <div
                  block="AMSSteps"
                  elem="SecondStepDots"
                >
                   { attachmentsDetails.map((item, index) => this.renderDots(index)) }
                </div>
                { this.renderSecondStepItems(stepOptions) }
                { this.renderStepMainImage(stepMainImage) }
            </div>
        );
    }

    renderThirdStep() {
        const { element: { children } } = this.props;
        const { children: second } = children.find(
            (child) => child?.attribs?.['data-content-type'] === 'scandiweb_ams_step_3'
        );
        const stepSubTitle = second?.find(({ name }) => name === HEADER3_NAME);
        const { children: stepOptions } = second?.find(({ name }) => name === DIV_NAME);

        return (
            <div
              block="AMSSteps"
              elem="ThirdStepWrapper"
            >
                { this.renderStepSubTitle(stepSubTitle) }
                { this.renderVideo(stepOptions) }
            </div>
        );
    }

    render() {
        return (
            <div
              block="AMSSteps"
              elem="Wrapper"
            >
                { this.renderFirstStep() }
                { this.renderSecondStep() }
                { this.renderThirdStep() }
            </div>
        );
    }
}

export default AMSStepsComponent;
