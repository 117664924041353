/* eslint-disable max-lines */
/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';

import { DIV_NAME, IMG_NAME } from '../AMSSteps/AMSSteps.config';
import {
    AMS_INFO_BLOCK_DESKTOP,
    AMS_INFO_BLOCK_MOBILE
} from './AMSInfoBlock.config';

import './AMSInfoBlock.style';

/** @namespace PageBuilder/Component/AMSInfoBlock/Component/AMSInfoBlockComponent */
export class AMSInfoBlockComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired,
        isMobile: PropTypes.bool.isRequired,
        selectedType: PropTypes.number.isRequired,
        setSelectedType: PropTypes.func.isRequired
    };

    renderMobileImage(content) {
        return domToReact(content);
    }

    renderButtons(content) {
        const { setSelectedType } = this.props;
        const filteredContent = content[0].filter(({ name = '' }) => name === DIV_NAME).map((item) => item);

        return filteredContent.map(({ attribs: { style } = {} }, index) => {
            if (style) {
                const styleObject = style
                    ?.split(';')
                    ?.filter(Boolean)
                    ?.reduce((acc, style) => {
                        const [property, value] = style.split(':');
                        if (property && value) {
                            acc[property.trim()] = value.trim();
                        }

                        return acc;
                    }, {});

                return (
                    <button
                      block="AMSInfoBlock"
                      elem="Button"
                      mods={ { Number: index } }
                      style={ styleObject || {} }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => setSelectedType(index) }
                      aria-label="Select"
                    >
                        <AddIcon />
                    </button>
                );
            }

            return (
                    <button
                      block="AMSInfoBlock"
                      elem="Button"
                      mods={ { Number: index } }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => setSelectedType(index) }
                      aria-label="Select"
                    >
                        <AddIcon />
                    </button>
            );
        });
    }

    renderDescription(content) {
        const { selectedType } = this.props;

        if (selectedType < 0) {
            return null;
        }

        const filteredContent = content[0]
            ?.filter(({ name = '' }) => name === DIV_NAME)
            ?.map((item) => {
                if (item?.attribs?.style) {
                    const { attribs, ...restItem } = item;
                    // eslint-disable-next-line no-unused-vars
                    const { style, ...restAttribs } = attribs;
                    const newItem = { ...restItem, attribs: restAttribs };

                    return newItem;
                }

                return item;
            });

        return domToReact([filteredContent[selectedType]]);
    }

    renderDesktop() {
        const { element: { children } } = this.props;
        const { children: desktopContent } = children.find(
            (child) => child?.attribs?.['data-content-type'] === AMS_INFO_BLOCK_DESKTOP
        );

        return domToReact(desktopContent);
    }

    renderMobile() {
        const { element: { children } } = this.props;
        const { children: mobileContent } = children.find(
            (child) => child?.attribs?.['data-content-type'] === AMS_INFO_BLOCK_MOBILE
        );

        const mobileImage = mobileContent.filter(({ name }) => name === IMG_NAME).map((item) => item);

        const descriptions = mobileContent.filter(({ name }) => name === DIV_NAME).map((item) => item.children);

        return (
            <>
            <div
              block="AMSInfoBlock"
              elem="Image"
            >
                { this.renderMobileImage(mobileImage) }
                { this.renderButtons(descriptions) }
            </div>
            <div
              block="AMSInfoBlock"
              elem="Descriptions"
            >
                { this.renderDescription(descriptions) }
            </div>
            </>
        );
    }

    render() {
        const { isMobile } = this.props;

        if (isMobile) {
            return (
                <div
                  block="AMSInfoBlock"
                  elem="Wrapper"
                >
                    { this.renderMobile() }
                </div>
            );
        }

        return (
            <div
              block="AMSInfoBlock"
              elem="Wrapper"
            >
                { this.renderDesktop() }
            </div>
        );
    }
}

export default AMSInfoBlockComponent;
