/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace AmastyFreeGift/Query/AmastyPromo/Query/AmastyPromoQuery */
export class AmastyPromoQuery {
    getQuery(cartId, data) {
        return new Field('amPromoAdd')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('data', '[AmGiftInput]!', data)
            .addFieldList(this.getFields());
    }

    getFields() {
        return [
            'status',
            'message'
        ];
    }
}

export default new AmastyPromoQuery();
