/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import AMSStepsComponent from './AMSSteps.component';

/** @namespace PageBuilder/Component/AMSSteps/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace PageBuilder/Component/AMSSteps/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PageBuilder/Component/AMSSteps/Container/AMSStepsContainer */
export class AMSStepsContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired
    };

    sliderRef = createRef();

    containerFunctions = {
        setSelectedType: this.setSelectedType.bind(this),
        setSelectedAttachment: this.setSelectedAttachment.bind(this),
        setAttachmentsDetails: this.setAttachmentsDetails.bind(this)
    };

    componentDidUpdate(prevProps, prevState) {
        const { selectedType, selectedAttachment, attachmentsDetails } = this.state;
        const { selectedType: prevType, selectedAttachment: prevAttachment } = prevState;

        if (selectedType !== prevType || selectedAttachment !== prevAttachment) {
            const activeVideo = selectedType * attachmentsDetails?.length + selectedAttachment;
            this.setState((prevState) => ({ ...prevState, activeVideo }));
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            selectedType: 0,
            selectedAttachment: 0,
            attachmentsDetails: [],
            activeVideo: 0
        };
    }

    containerProps() {
        const { element } = this.props;
        const {
            selectedType, selectedAttachment, attachmentsDetails, activeVideo
        } = this.state;

        return {
            element,
            selectedType,
            selectedAttachment,
            attachmentsDetails,
            activeVideo,
            sliderRef: this.sliderRef
        };
    }

    setSelectedType(value) {
        this.setState((prevState) => ({ ...prevState, selectedType: value }));
    }

    setSelectedAttachment(value) {
        const { selectedAttachment, attachmentsDetails } = this.state;
        if (typeof (value) === 'boolean') {
            const newValue = value ? selectedAttachment - 1 : selectedAttachment + 1;
            this.setState((prevState) => ({
                ...prevState,
                // eslint-disable-next-line no-nested-ternary
                selectedAttachment: newValue < 0 ? attachmentsDetails?.length - 1
                    : (newValue > attachmentsDetails?.length - 1 ? 0 : newValue)
            }));

            return;
        }
        this.setState((prevState) => ({ ...prevState, selectedAttachment: Math.abs(value) }));
    }

    setAttachmentsDetails(value) {
        this.setState((prevState) => ({ ...prevState, attachmentsDetails: value }));
    }

    render() {
        return (
            <AMSStepsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AMSStepsContainer);
