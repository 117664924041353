import { mapDispatchToProps, mapStateToProps }
from '@scandipwa/scandipwa/src/component/MyAccountAddressForm/MyAccountAddressForm.container';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

/** @namespace Scandipwa/Component/CheckoutAddressForm/Container/CheckoutAddressFormContainer */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    static propTypes = {
        ...super.propTypes,
        isManualAddress: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isManualAddress: false
    };

    containerProps() {
        const { isManualAddress, id } = this.props;

        return {
            ...super.containerProps(),
            isManualAddress,
            id
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);
