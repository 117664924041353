/**
 * Checkout.com package for SPWA for card payments
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CheckoutComCardProvider } from '../../context/CheckoutComCard';

const addCheckoutComCardProvider = (member) => [
    (children) => (
        <CheckoutComCardProvider>
            { children }
        </CheckoutComCardProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addCheckoutComCardProvider
        }
    }
};
