/**
 * @category  Scandiweb
 * @package   ScandiPWA/AdenAnais
 * @author    Anastasija Zubkova <info@scandiweb.com>
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';

import { EleType } from '../../type/Accordion';

/** @namespace PageBuilder/Component/Accordion/Component/AccordionComponent */
export class AccordionComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            PropTypes.array
        ]).isRequired,
        Ele: EleType.isRequired,
        isDesktopDisabled: PropTypes.number.isRequired
    };

    render() {
        const {
            title,
            content,
            Ele,
            isDesktopDisabled
        } = this.props;

        return (
            <ExpandableContent
              heading={ title }
              useOnDesktop={ !isDesktopDisabled }
              mix={ { block: 'Accordion' } }
            >
                <Ele>
                    { content }
                </Ele>
            </ExpandableContent>
        );
    }
}

export default AccordionComponent;
