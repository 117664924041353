import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device.type';

import { PageBuilderSliderElementsType } from '../../type/PageBuilderSlider';
import Slider from './Slider.component';
import {
    DEFAULT_AUTOPLAY_VALUE,
    DEFAULT_VISIBLE_SLIDES,
    DIRECTION_RIGHT,
    SLIDER_DISABLED_DESKTOP_CLASS,
    TRUE_STRING
} from './Slider.config';

/** @namespace PageBuilder/Component/Slider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace PageBuilder/Component/Slider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PageBuilder/Component/Slider/Container/SliderContainer */
export class SliderContainer extends PureComponent {
    static propTypes = {
        elements: PageBuilderSliderElementsType.isRequired,
        device: DeviceType.isRequired
    };

    containerFunctions = {
        onActiveImageChange: this.onActiveImageChange.bind(this)
    };

    componentDidMount() {
        const {
            'data-autoplay': dataAutoplay,
            'data-autoplay-speed': dataAutoplaySpeed
        } = this.sliderProps;

        if (dataAutoplay === TRUE_STRING) {
            this.startCarousel(
                dataAutoplaySpeed
                || DEFAULT_AUTOPLAY_VALUE
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.carouselInterval);
    }

    __construct(props) {
        super.__construct(props);

        const {
            elements: {
                BaseSlider: {
                    propsBag: basePropsBag
                },
                Slide: {
                    propsBag
                }
            }
        } = props;

        this.sliderProps = basePropsBag?.length > 0 ? basePropsBag[0] : {};
        this.slidesProps = propsBag?.length > 0 ? propsBag : [];

        this.state = {
            activeImage: 0,
            carouselDirection: DIRECTION_RIGHT,
            imageToShow: 0
        };
    }

    startCarousel = (interval) => {
        this.carouselInterval = setInterval(() => {
            this.getImageToShow();

            const { imageToShow } = this.state;

            this.onActiveImageChange(imageToShow);
        }, interval);
    };

    getImageToShow() {
        const { activeImage, carouselDirection } = this.state;

        if (!activeImage && !this.isInfiniteLoop()) {
            this.setState({
                carouselDirection: DIRECTION_RIGHT,
                imageToShow: activeImage + 1
            });
        } else if (activeImage === this.slidesProps.length - 1 && !this.isInfiniteLoop()) {
            this.setState({
                carouselDirection: 'left',
                imageToShow: activeImage - 1
            });
        } else {
            this.setState({ imageToShow: carouselDirection === DIRECTION_RIGHT ? activeImage + 1 : activeImage - 1 });
        }
    }

    getVisibleSlidesCount() {
        const {
            elements: {
                Slide: {
                    childData
                } = {}
            } = {},
            device = {}
        } = this.props;

        const { visibleSlides = DEFAULT_VISIBLE_SLIDES } = this.sliderProps;

        const visibleSlidesCount = device.isMobile ? DEFAULT_VISIBLE_SLIDES : visibleSlides;

        return Math.min(visibleSlidesCount, childData.length);
    }

    /**
     * Make all slides visible on desktop if slider should be disabled on desktop
     * and slide amount is the same visible slide count
     *
     * @returns {number|*}
     */
    getActiveSlide() {
        const {
            elements: {
                Slide: {
                    childData = []
                } = {}
            } = {}
        } = this.props;
        const { activeImage } = this.state;
        const isSliderDisabledOnDesktop = this.isSliderDisabledOnDesktop();
        const visibleSlides = this.getVisibleSlidesCount();

        if (!isSliderDisabledOnDesktop || childData.length !== visibleSlides) {
            return activeImage;
        }

        return Math.ceil(visibleSlides / 2) - 1;
    }

    /**
     * Check if actions must be disabled on desktop
     *
     * @returns {boolean|*}
     */
    isSliderDisabledOnDesktop() {
        const { className } = this.sliderProps;
        const { device: { isMobile } } = this.props;

        return !isMobile && className.includes(SLIDER_DISABLED_DESKTOP_CLASS);
    }

    onActiveImageChange(activeImage) {
        this.setState({ activeImage });
    }

    /**
     * Check if slider has infinite loop enabled
     */
    isInfiniteLoop() {
        const {
            'data-infinite-loop': isInfiniteLoop
        } = this.sliderProps;

        return isInfiniteLoop === true || isInfiniteLoop === TRUE_STRING;
    }

    containerProps = () => {
        const {
            elements: {
                Slide
            } = {}
        } = this.props;

        return {
            activeImage: this.getActiveSlide(),
            Slide,
            sliderProps: this.sliderProps,
            sliderVisibleSlidesCount: this.getVisibleSlidesCount(),
            isInteractionDisabled: this.isSliderDisabledOnDesktop(),
            isInfiniteLoop: this.isInfiniteLoop()
        };
    };

    render() {
        return (
            <Slider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SliderContainer);
