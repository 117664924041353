/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';
import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

import { BLOG_ARCHIVE_PAGE_IDENTIFIER } from '../route/BlogArchive/BlogArchive.config';
import { BLOG_PAGE_IDENTIFIER } from '../route/BlogHomePage/BlogHomePage.config';

export const BlogArchive = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms" */
    '../route/BlogArchive'
));
export const BlogHomePage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms" */
    '../route/BlogHomePage'
));
export const CategoryArchive = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms" */
    '../route/CategoryArchive'
));
export const PostsDetails = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "cms" */
    '../route/PostsDetails'
));

export class RouterPlugin {
    /**
     * Adds blog url handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
    switchItems = (originalMember) => {
        const blogURL = withStoreRegex(`/${BLOG_PAGE_IDENTIFIER}`);

        return [
            ...originalMember,
            {
                component: this._getBlogArchivePage(withStoreRegex(`/${BLOG_ARCHIVE_PAGE_IDENTIFIER}`)),
                position: 140
            },
            {
                component: this._getCategoryArchive(blogURL),
                position: 150
            },
            {
                component: this._getBlogPostPage(blogURL),
                position: 160
            },
            {
                component: this._getBlogHomePage(blogURL),
                position: 170
            }
        ];
    };

    _getBlogArchivePage(url) {
        return <Route path={ url } component={ BlogArchive } />;
    }

    _getCategoryArchive(url) {
        return <Route path={ `${url}/category/:handle` } component={ CategoryArchive } />;
    }

    _getBlogPostPage(url) {
        return <Route path={ `${url}/:handle` } component={ PostsDetails } />;
    }

    _getBlogHomePage(url) {
        return <Route path={ url } component={ BlogHomePage } />;
    }
}

const { switchItems } = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: switchItems
        }
    }
};

export default config;
