import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ScandiPwaSlider from 'SourceComponent/Slider';

import { PageBuilderSlideType } from '../../type/PageBuilderSlider';
import { DEFAULT_AUTOPLAY_VALUE, TRUE_STRING } from './Slider.config';

/** @namespace PageBuilder/Component/Slider/Component/SliderComponent */
export class SliderComponent extends PureComponent {
    static propTypes = {
        Slide: PageBuilderSlideType.isRequired,
        sliderProps: PropTypes.shape({
            className: PropTypes.string,
            'data-appearance': PropTypes.string,
            'data-autoplay': PropTypes.string,
            'data-autoplay-speed': PropTypes.number,
            'data-content-type': PropTypes.string,
            'data-element': PropTypes.string,
            'data-fade': PropTypes.string,
            'data-hero-banner': PropTypes.string,
            'data-infinite-loop': PropTypes.string,
            'data-pb-style': PropTypes.string,
            'data-show-arrows': PropTypes.string,
            'data-show-dots': PropTypes.string,
            visibleSlides: PropTypes.number
        }).isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        sliderVisibleSlidesCount: PropTypes.number.isRequired,
        isInteractionDisabled: PropTypes.bool.isRequired,
        isInfiniteLoop: PropTypes.bool.isRequired
    };

    renderSlide = (slide, i) => {
        const {
            Slide: {
                childEleBag,
                Ele
            }
        } = this.props;

        return (
            <Ele key={ i }>
                { childEleBag[i] }
            </Ele>
        );
    };

    renderHeroBannerSlide = (slide, i) => {
        const {
            Slide: {
                childEleBag,
                Ele,
                propsBag
            }
        } = this.props;

        const {
            [i]: {
                className,
                'data-appearance': dataAppearance
            }
        } = propsBag;

        return (
            <div
              key={ i }
              block="Slider"
              elem="ItemWrapper"
              className={ className }
              data-appearance={ dataAppearance }
            >
                <Ele>
                { childEleBag[i] }
                </Ele>
            </div>
        );
    };

    render() {
        const {
            Slide: { propsBag },
            activeImage,
            sliderProps: {
                'data-hero-banner': dataHeroBanner,
                'data-show-dots': dataShowDots,
                'data-show-arrows': dataShowArrows,
                'data-autoplay': dataAutoplay,
                'data-autoplay-speed': dataAutoplaySpeed,
                className
            },
            onActiveImageChange,
            sliderVisibleSlidesCount,
            isInteractionDisabled,
            isInfiniteLoop
        } = this.props;

        return (
            <div>
                <ScandiPwaSlider
                  mix={ { block: 'PageBuilderSlider' } }
                  showCrumbs={ dataShowDots === TRUE_STRING }
                  showArrows={ dataShowArrows === TRUE_STRING }
                  isAnimationEnabled={ dataAutoplay === TRUE_STRING }
                  autoplaySpeed={ dataAutoplaySpeed || DEFAULT_AUTOPLAY_VALUE }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  visibleSlides={ sliderVisibleSlidesCount }
                  className={ className }
                  isInteractionDisabled={ isInteractionDisabled }
                  isInfiniteLoop={ isInfiniteLoop }
                >
                    { propsBag.map(this.renderSlide) }
                </ScandiPwaSlider>
            </div>
        );
    }
}

export default SliderComponent;
