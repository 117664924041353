import { StoreFinderReducer } from '../store/StoreFinder';

/** @namespace Scandiweb/StoreFinderGraphQl/Plugin/Plugin */
export class StoreIndexPlugin {
    aroundGetReducer = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        StoreFinderReducer
    });
}

const {
    aroundGetReducer
} = new StoreIndexPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: [
            {
                position: 100,
                implementation: aroundGetReducer
            }
        ]
    }
};

export default config;
