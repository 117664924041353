/* eslint-disable spaced-comment */
/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    ProductBundleOption as SourceProductBundleOption
} from 'SourceComponent/ProductBundleOption/ProductBundleOption.component';
import { ItemOptionsType } from 'Type/ProductList.type';
import { bundleOptionToLabel } from 'Util/Product/Transform';

/**
 * Product Bundle Option
 * @class ProductBundleOption
 * @namespace Scandipwa/Component/ProductBundleOption/Component/ProductBundleOptionComponent */
export class ProductBundleOptionComponent extends SourceProductBundleOption {
    static propTypes = {
        uid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        quantity: PropTypes.objectOf(PropTypes.number).isRequired,
        setQuantity: PropTypes.func.isRequired,
        isRequired: PropTypes.bool.isRequired,
        options: PropTypes.arrayOf(ItemOptionsType).isRequired,
        currencyCode: PropTypes.string.isRequired,
        activeSelectUid: PropTypes.string,
        setActiveSelectUid: PropTypes.func.isRequired,
        getDropdownOptions: PropTypes.func.isRequired,
        updateSelectedValues: PropTypes.func.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getLabel(option) {
        const currencyCode = option.product.price_range.minimum_price.final_price.currency;

        const {
            baseLabel,
            priceLabel
        } = bundleOptionToLabel(option, currencyCode);

        return (
            <div block="ProductBundleItem" elem="Label">
                { baseLabel }
                <strong block="ProductBundleItem" elem="PriceLabel">{ `${priceLabel}` }</strong>
            </div>
        );
    }
    //#endregion
}

export default ProductBundleOptionComponent;
