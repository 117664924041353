import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace PageBuilder/Component/HtmlCode/Component/HtmlCodeComponent */
export class HtmlCodeComponent extends PureComponent {
    static propTypes = {
        element: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ])
        ).isRequired,
        elements: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]).isRequired
    };

    render() {
        const {
            element,
            elements: {
                BaseHtmlCode
            } = {}
        } = this.props;

        return (
            <BaseHtmlCode.Ele>
                { element }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
