/**
 * Amasty Shipping Suite compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-param-reassign */

import transformToNameValuePair from 'Util/Form/Transform';

const addAddressToState = (args, callback, instance) => {
    callback(...args);

    instance.state = {
        ...instance.state,
        address: {
            country_id: null,
            region_id: null,
            region: null,
            city: null,
            postcode: null,
            street: []
        }
    };
};

const estimateShippingCosts = (instance, newAddress) => {
    const {
        onShippingEstimationFieldsChange
    } = instance.props;

    const { address } = instance.state;

    // update state with combined address group and street group
    instance.setState((prevState) => ({
        address: {
            ...prevState.address,
            ...newAddress
        }
    }));

    // ↓↓ pass the updated address to estimateShippingCosts mutation
    onShippingEstimationFieldsChange({
        ...address,
        ...newAddress
    });
};

const getShippingEstimationFromProps = (_args, _callback, instance) => {
    const {
        address: {
            countryId,
            regionId,
            region,
            city,
            postcode,
            street = []
        },
        defaultCountry,
        onShippingEstimationFieldsChange
    } = instance.props;

    onShippingEstimationFieldsChange({
        country_id: countryId || defaultCountry,
        region_id: regionId !== '' ? regionId : null,
        region,
        city,
        postcode,
        street
    });
};

const updateShippingEstimationOnAddressChange = (args, _callback, instance) => {
    const { fields = {} } = args[1];

    const {
        country_id,
        region_id: regionId,
        region_string: region,
        city,
        postcode
    } = transformToNameValuePair(fields);

    const request = {
        country_id,
        region_id: regionId !== '' ? regionId : null,
        region,
        city,
        postcode
    };

    // If request hasn't changed, then ignore.
    if (JSON.stringify(request) === JSON.stringify(instance.lastRequest)) {
        return;
    }

    estimateShippingCosts(instance, request);

    // ↓↓ Caches last request
    instance.lastRequest = request;
};

export default {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            __construct: addAddressToState,
            componentDidMount: getShippingEstimationFromProps,
            onAddressChange: updateShippingEstimationOnAddressChange
        }
    }
};
