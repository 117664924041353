import { AmastyFreeGiftReducer } from '../store/AmastyFreeGift.reducer';

export class StoreIndexPlugin {
    aroundGetReducer = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        AmastyFreeGiftReducer
    });
}

const {
    aroundGetReducer
} = new StoreIndexPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: [
            {
                position: 110,
                implementation: aroundGetReducer
            }
        ]
    }
};

export default config;
