import { Field } from 'Util/Query';

/** @namespace ProductLabel/Plugin/ProductListQuery/Plugin/ProductListQueryPlugin */
export class ProductListQueryPlugin {
    aroundGetProductInterfaceFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'new_from_date',
        'new_to_date',
        this._getLabelFields()
    ];

    _getLabelFields() {
        return new Field('mp_label_data')
            .addFieldList(this._getProductItemsListFields())
            .addFieldList(this._getProductItemsLabelFields());
    }

    _getProductItemsLabelFields() {
        return [
            'label',
            'label_color',
            'label_css',
            'label_image'
        ];
    }

    _getProductItemsListFields() {
        return [
            'list_label',
            'list_color',
            'list_css',
            'list_image',
            'shipping_icon',
            'enabled'
        ];
    }

    aroundGetCartProductInterfaceFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'new_from_date',
        'new_to_date',
        this._getLabelFields()
    ];
}

const { aroundGetProductInterfaceFields, aroundGetCartProductInterfaceFields } = new ProductListQueryPlugin();

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: {
                position: 100,
                implementation: aroundGetProductInterfaceFields
            },
            _getCartProductInterfaceFields: {
                position: 100,
                implementation: aroundGetCartProductInterfaceFields
            }
        }
    }
};
