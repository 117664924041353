/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { connect } from 'react-redux';

import PrivacyPolicyPopupComponent from './PrivacyPolicyPopup.component';

/** @namespace Gdpr/Component/PrivacyPolicyPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload
});

/** @namespace Gdpr/Component/PrivacyPolicyPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicyPopupComponent);
