/* eslint-disable no-console */

import { formatPrice } from 'Util/Price/Price';

import { EVENT_GTM_CHECKOUT, EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const onShippingSuccess = (args, callback, instance) => {
    const { event, totals: { quote_currency_code = 'GBP' } } = instance.props;
    const {
        selectedShippingMethod: {
            method_title,
            price_incl_tax
        }
    } = instance.state;

    const shippingPrice = formatPrice(price_incl_tax, quote_currency_code);
    const data = {
        option: `${method_title} - ${shippingPrice}`,
        step: 1,
        isInternational: false
    };

    callback.apply(instance, args);

    event(EVENT_GTM_CHECKOUT_OPTION, data);

    console.log('EVENT_GTM_CHECKOUT_OPTION', data);
};

const componentDidMount = (args, callback, instance) => {
    const { event, methodWithRedirect } = instance.props;

    if (methodWithRedirect) {
        return callback.apply(instance, args);
    }

    event(EVENT_GTM_CHECKOUT, { step: 1 });

    console.log('EVENT_GTM_CHECKOUT', { step: 1 });

    callback.apply(instance, args);

    return null;
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess,
            componentDidMount
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
