/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CLEAR_STORE_FINDER, UPDATE_STORE_FINDER } from './StoreFinder.action';

/** @namespace StoreFinder/Store/StoreFinder/Reducer/getInitialState */
export const getInitialState = () => ({
    storeListCities: [],
    storeListMapped: {},
    storeById: {}
});

/** @namespace StoreFinder/Store/StoreFinder/Reducer/StoreFinderReducer */
export const StoreFinderReducer = (state = getInitialState(), action) => {
    const { storeList, type } = action;

    switch (type) {
    case UPDATE_STORE_FINDER:
        const storeListObject = storeList.reduce((cities, store) => {
            const {
                city,
                longitude,
                latitude,
                is_active,
                identifier
            } = store;

            if (city && longitude && latitude && is_active) {
                if (!cities.storeListCities.includes(city)) {
                    cities.storeListCities.push(city);
                }

                if (!cities.storeListMapped[city]) {
                    // eslint-disable-next-line no-param-reassign
                    cities.storeListMapped[city] = [];
                }

                cities.storeListMapped[city].push(store);

                // eslint-disable-next-line no-param-reassign
                cities.storeById[identifier] = store;
            }

            return cities;
        }, { storeListCities: [], storeListMapped: {}, storeById: {} });

        return {
            ...state,
            ...storeListObject
        };
    case CLEAR_STORE_FINDER:
        return getInitialState();
    default:
        return state;
    }
};

export default StoreFinderReducer;
