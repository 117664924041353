/* eslint-disable no-console */

import { EVENT_GTM_CHECKOUT, EVENT_GTM_CHECKOUT_OPTION } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const onBillingSuccess = (args, callback, instance) => {
    const { event } = instance.props;
    const { paymentMethod } = instance.state;

    const data = {
        option: paymentMethod,
        step: 2
    };

    callback.apply(instance, args);

    event(EVENT_GTM_CHECKOUT_OPTION, data);

    console.log('EVENT_GTM_CHECKOUT_OPTION', data);
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;

    event(EVENT_GTM_CHECKOUT, { step: 2 });

    console.log('EVENT_GTM_CHECKOUT', { step: 2 });

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess,
            componentDidMount
        }
    },
    'Component/CheckoutBilling/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
