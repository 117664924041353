import SearchItem from 'Component/SearchItem';
import { SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_SITE_SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { preparingSearchResults } from 'Store/SearchBar/SearchBar.action';

const renderSearchItem = (args, callback, instance) => {
    const [product, i] = args;
    const { searchCriteria } = instance.props;

    return (
            <SearchItem
              product={ product }
              searchCriteria={ searchCriteria }
              key={ i }
              position={ i + 1 }
            />
    );
};

const renderSearchResults = (args, callback, instance) => {
    const {
        searchResults,
        isLoading,
        searchCriteria,
        event,
        preparingSearchResults,
        isPreparingRequest
    } = instance.props;

    if (!isLoading && isPreparingRequest) {
        event(EVENT_GTM_SITE_SEARCH, {
            products: searchResults.length, search: searchCriteria, list: SEARCH
        });
        preparingSearchResults(false);
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        preparingSearchResults: (isPreparingRequest) => dispatch(preparingSearchResults(isPreparingRequest))
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isPreparingRequest: state.SearchBarReducer.isPreparingRequest
    };
};

const containerProps = (args, callback, instance) => {
    const {
        event,
        preparingSearchResults,
        isPreparingRequest
    } = instance.props;

    return {
        ...callback(...args),
        event,
        preparingSearchResults,
        isPreparingRequest
    };
};

export default {
    'Component/SearchOverlay/Component': {
        'member-function': {
            renderSearchItem,
            renderSearchResults
        }
    },
    'Component/SearchOverlay/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/SearchOverlay/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/SearchOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
