/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AMSProductDetailsComponent from './AMSProductDetails.component';
import { TEXT_TYPE } from './AMSProductDetails.config';

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Product/Product.dispatcher'
);

/** @namespace PageBuilder/Component/AMSProductDetails/Container/AMSProductDetailsContainer */
export class AMSProductDetailsContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired
    };

    containerProps() {
        const { element: { children } } = this.props;

        const data = children.find(({ type }) => type === TEXT_TYPE).data.trim().split(',');

        return {
            sku: data[0],
            id: Number(data[1])
        };
    }

    render() {
        return (
            <AMSProductDetailsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default AMSProductDetailsContainer;
