/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class ConfigQueryPlugin {
    /**
     * Get blog posts per page into config query
     * @returns [Field]
     */
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        ...this.getBlogConfigFields()
    ];

    getBlogConfigFields() {
        return [
            new Field('mfblog_social_add_this_pubid').setAlias('addThisPubId'),
            new Field('mfblog_social_add_this_enabled').setAlias('isAddThisEnabled'),
            new Field('mfblog_social_add_this_language').setAlias('addThisLanguage'),
            new Field('mfblog_index_page_post_ids').setAlias('featuredPostsIds'),
            new Field('mfblog_top_posts_menu_top_posts_title').setAlias('featuredPostsTitle'),
            new Field('mfblog_top_posts_menu_top_posts_more_label').setAlias('featuredPostsReadLabel')
        ];
    }
}

export const { _aroundGetStoreConfigFields } = new ConfigQueryPlugin();

export const config = {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields
        }
    }
};

export default config;
