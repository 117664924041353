import {
    EVENT_GTM_GOOGLE_CONSENT, EVENT_KEY_CONSENT
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const aroundAfterSetCookiesState = (args, callback, instance) => {
    const { event } = instance.props;

    callback(...args);

    const { isAnalyticsAccepted, isMarketingAccepted } = instance.state;

    event(EVENT_GTM_GOOGLE_CONSENT, [
        EVENT_KEY_CONSENT,
        'update',
        {
            ad_storage: isMarketingAccepted ? 'granted' : 'denied',
            ad_user_data: isMarketingAccepted ? 'granted' : 'denied',
            ad_personalization: isMarketingAccepted ? 'granted' : 'denied',
            analytics_storage: isAnalyticsAccepted ? 'granted' : 'denied'
        }]);
};

export default {
    'Component/CookiePopup/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CookiePopup/Container': {
        'member-function': {
            setCookiesState: aroundAfterSetCookiesState
        }
    }
};
