import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import Image from 'Component/Image';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';

const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product" */
    '../component/ProductLabel'
));

export class ProductGalleryPlugin {
    containerProps = (args, callback, instance) => {
        const instanceProps = callback(...args);
        const { product, product: { mp_label_data: productLabels } } = instance.props;

        return {
            ...instanceProps,
            productLabels,
            product
        };
    };

    addLabelPropTypes = (args) => ({
        ...args,
        productLabels: PropTypes.arrayOf(PropTypes.shape({
            enabled: PropTypes.number,
            label: PropTypes.string,
            label_color: PropTypes.string,
            label_css: PropTypes.string,
            list_label: PropTypes.string,
            list_color: PropTypes.string,
            list_css: PropTypes.string,
            shipping_icon: PropTypes.number
        }))
    });

    addLabelDefaultProps = (args) => ({
        ...args,
        productLabels: [],
        product: {}
    });

    renderImage = (args, callback, instance) => {
        const {
            isMobile,
            isImageZoomPopupActive,
            productName,
            handleZoomChange,
            scrollEnabled,
            isZoomEnabled,
            disableZoom,
            productLabels,
            product
        } = instance.props;

        if (!productLabels || !productLabels.length) {
            return callback(...args);
        }

        const [mediaData, index] = args;

        if (!isMobile) {
            const {
                base: { url: baseSrc } = {},
                large: { url: largeSrc } = {},
                label
            } = mediaData;
            const style = isImageZoomPopupActive ? { height: 'auto' } : {};
            const src = isImageZoomPopupActive ? largeSrc : baseSrc;

            return (
                <Image
                  key={ index }
                  src={ src }
                  ratio="custom"
                  mix={ {
                      block: 'ProductGallery',
                      elem: 'SliderImage',
                      mods: { isPlaceholder: !src }
                  } }
                  isPlaceholder={ !src }
                  style={ style }
                  alt={ label || productName }
                  productLabels={ productLabels }
                  product={ product }
                  isImageZoomPopupActive={ isImageZoomPopupActive }
                />
            );
        }

        return (
            <TransformWrapper
              key={ index }
              onZoomChange={ handleZoomChange }
              onWheelStart={ instance.onWheelStart }
              onWheel={ instance.onWheel }
              wheel={ { limitsOnWheel: true, disabled: !scrollEnabled } }
              pan={ {
                  disabled: !isZoomEnabled,
                  limitToWrapperBounds: true,
                  velocity: false
              } }
              options={ {
                  limitToBounds: true,
                  minScale: 1
              } }
            >
                { ({
                    scale,
                    previousScale,
                    resetTransform,
                    setTransform
                }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                          productLabels={ productLabels }
                          product={ product }
                          setTransform={ setTransform }
                          index={ index }
                          mediaData={ mediaData }
                          scale={ scale }
                          previousScale={ previousScale }
                          disableZoom={ disableZoom }
                          isZoomEnabled={ isZoomEnabled }
                          isImageZoomPopupActive={ isImageZoomPopupActive }
                        />
                    );
                } }
            </TransformWrapper>
        );
    };

    renderProductLabels(instance) {
        const {
            productLabels,
            product: {
                price_range: {
                    minimum_price = {}
                } = {}
            } = {},
            isImageZoomPopupActive
        } = instance.props;

        if (!productLabels.length || isImageZoomPopupActive) {
            return null;
        }

        return (
            <ProductLabel
              labelsData={ productLabels }
              minPriceRange={ minimum_price }
              isPDP
            />
        );
    }
}

const {
    containerProps, addLabelPropTypes, addLabelDefaultProps, renderImage
} = new ProductGalleryPlugin();

export default {
    'Component/ProductGallery/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    },
    'Component/ProductGallery/Component': {
        'member-function': {
            renderImage
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    }
};
