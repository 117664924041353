/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executePost, QueryDispatcher } from 'Util/Request';

import StoreFinderQuery from '../../query/StoreFinder.query';
import { clearStoreFinder, updateStoreFinder } from './StoreFinder.action';

/**
 * Store Finder Dispatcher
 * @class StoreFinderDispatcher
 * @extends QueryDispatcher
 * @namespace StoreFinder/Store/StoreFinder/Dispatcher/StoreFinderDispatcher */
export class StoreFinderDispatcher extends QueryDispatcher {
    __construct() {
        super.__construct();
    }

    onSuccess({ getStores }, dispatch) {
        dispatch(updateStoreFinder(getStores));
    }

    onError(error, dispatch) {
        dispatch(updateNoMatch(true));
    }

    requestStoreData(dispatch) {
        const query = StoreFinderQuery.getQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace StoreFinder/Store/StoreFinder/Dispatcher/executePost/then */
            ({ getStores }) => {
                dispatch(updateStoreFinder(getStores));
            },
            /** @namespace StoreFinder/Store/StoreFinder/Dispatcher/executePost/then */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    }

    removeStoreData(dispatch) {
        dispatch(clearStoreFinder());
    }
}

export default new (StoreFinderDispatcher)();
