/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @author Scandiweb info@scandiweb.com>
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutComQuery from 'Query/CheckoutCom.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import {
    ONE_HOUR_IN_SECONDS
} from '../Checkout/Checkout.config';
import { CheckoutComThreeDsComponent } from './CheckoutComThreeDs.component';
import {
    CHECKOUT_COM_ERROR
} from './CheckoutComThreeDs.config';

import './CheckoutComThreeDs.style';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Route/CheckoutComThreeDs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    turnOffBreadcrumbs: () => dispatch(toggleBreadcrumbs(false)),
    createGuestEmptyCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createGuestEmptyCart(dispatch)
    )
});

/** @namespace Scandipwa/Route/CheckoutComThreeDs/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Route/CheckoutComThreeDs/Container/CheckoutComThreeDsContainer */
export class CheckoutComThreeDsContainer extends PureComponent {
    static propTypes = {
        location: PropTypes.shape({}).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                type: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        turnOffBreadcrumbs: PropTypes.func.isRequired,
        createGuestEmptyCart: PropTypes.func.isRequired
    };

    state = {
        isLoading: true
    };

    componentDidMount() {
        const {
            location,
            match: { params: { type } },
            turnOffBreadcrumbs
        } = this.props;

        turnOffBreadcrumbs();

        const sessionId = this.getQueryParam('cko-session-id', location);

        this.handleResponse(sessionId, type);
    }

    handleResponse(sessionId, type) {
        if (!sessionId) {
            BrowserDatabase.setItem('No payment ID found.', CHECKOUT_COM_ERROR, ONE_HOUR_IN_SECONDS);
            history.push(appendWithStoreCode('/checkout'));
        } else if (type === 'verify') {
            this.verifyOrder(sessionId);
        } else {
            this.failOrder(sessionId);
        }
    }

    getQueryParam = (variable, location) => {
        const query = location.search.substring(1);
        const vars = query.split('&');

        // eslint-disable-next-line fp/no-loops,fp/no-let
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }

        return false;
    };

    verifyOrder(sessionId) {
        try {
            const mutation = CheckoutComQuery.getVerifyCheckoutCom3dSecureMutation(sessionId);
            fetchMutation(mutation).then(
                /** @namespace Scandipwa/Route/CheckoutComThreeDs/Container/fetchMutation/then */
                ({
                    verifyCheckoutCom:
                         {
                             error = null
                         }
                }) => {
                    if (error) {
                        BrowserDatabase.setItem(error, CHECKOUT_COM_ERROR, ONE_HOUR_IN_SECONDS);
                    }
                    history.push(appendWithStoreCode('/checkout'));
                }
            );
        } catch (e) {
            BrowserDatabase.setItem(e, CHECKOUT_COM_ERROR, ONE_HOUR_IN_SECONDS);
            history.push(appendWithStoreCode('/checkout'));
        }
    }

    failOrder(sessionId) {
        try {
            const mutation = CheckoutComQuery.getFailCheckoutCom3dSecureMutation(sessionId);
            fetchMutation(mutation).then(
                /** @namespace Scandipwa/Route/CheckoutComThreeDs/Container/fetchMutation/then */
                ({ failCheckoutCom: error = null }) => {
                    if (error) {
                        BrowserDatabase.setItem(error, CHECKOUT_COM_ERROR, ONE_HOUR_IN_SECONDS);
                    }
                    history.push(appendWithStoreCode('/checkout'));
                }
            );
        } catch (e) {
            BrowserDatabase.setItem(e, CHECKOUT_COM_ERROR, ONE_HOUR_IN_SECONDS);
            history.push(appendWithStoreCode('/checkout'));
        }
    }

    containerProps = () => {
        const { turnOffBreadcrumbs } = this.props;
        const { isLoading } = this.state;

        return {
            turnOffBreadcrumbs,
            isLoading
        };
    };

    render() {
        return (
            <CheckoutComThreeDsComponent
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComThreeDsContainer);
