import { ERROR_TYPE } from 'Component/Notification/Notification.config';
import { deleteAuthorizationToken, isSignedIn } from 'Util/Auth';

export const aroundComponentDidMount = (args, callback, instance) => {
    const { notification: { msgType, msgText } } = instance.props;

    if (msgType.toLowerCase() === ERROR_TYPE
        && msgText && msgText.toLowerCase().includes('customer')
        && msgText.toLowerCase().includes('authorized')
    ) {
        deleteAuthorizationToken();
        isSignedIn();
    }

    callback.apply(instance, args);
};

export default {
    'Component/Notification/Component': {
        'member-function': {
            componentDidMount: aroundComponentDidMount
        }
    }
};
