import PropTypes from 'prop-types';
import { lazy } from 'react';

const ProductLabel = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product" */
    '../component/ProductLabel/ProductLabel.container'
));

export class ImagePlugin {
    addLabelPropTypes = (args) => ({
        ...args,
        productLabels: PropTypes.arrayOf(PropTypes.shape({
            enabled: PropTypes.number,
            label: PropTypes.string,
            label_color: PropTypes.string,
            label_css: PropTypes.string,
            list_label: PropTypes.string,
            list_color: PropTypes.string,
            list_css: PropTypes.string,
            shipping_icon: PropTypes.number
        })),
        isImageZoomPopupActive: PropTypes.bool || null
    });

    addLabelDefaultProps = (args) => ({
        ...args,
        productLabels: [],
        product: {},
        isImageZoomPopupActive: null
    });

    containerProps = (args, callback, instance) => {
        const instanceProps = callback(...args);
        const { productLabels, product, isImageZoomPopupActive } = instance.props;

        return {
            ...instanceProps,
            productLabels,
            product,
            isImageZoomPopupActive
        };
    };

    renderLabels(instance) {
        const {
            productLabels,
            product: {
                price_range: {
                    minimum_price = {}
                } = {}
            } = {},
            isImageZoomPopupActive
        } = instance.props;

        if (!productLabels.length || isImageZoomPopupActive === true) {
            return null;
        }

        return (
            <ProductLabel
              labelsData={ productLabels }
              minPriceRange={ minimum_price }
              isPDP
            />
        );
    }

    render = (args, callback, instance) => {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain,
            productLabels
        } = instance.props;

        if (!productLabels || !productLabels.length) {
            return callback(...args);
        }

        const { imageStatus } = instance.state;

        // render image as is: without additional container and additional styles
        if (isPlain) {
            return instance.renderImage();
        }

        return (
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  ratio,
                  imageStatus: imageStatus.toLowerCase(),
                  isPlaceholder,
                  hasSrc: !!src
              } }
              mix={ mix }
              style={ wrapperSize }
                // eslint-disable-next-line react/forbid-dom-props
              className={ className }
            >
                { instance.renderImage() }
                { this.renderLabels(instance) }
                { instance.renderLoader() }
            </div>
        );
    };
}

const {
    addLabelPropTypes, addLabelDefaultProps, containerProps, render
} = new ImagePlugin();

export default {
    'Component/Image/Container': {
        'member-function': {
            containerProps
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    },
    'Component/Image/Component': {
        'member-function': {
            render
        },
        'static-member': {
            propTypes: addLabelPropTypes,
            defaultProps: addLabelDefaultProps
        }
    }
};
