/**
 * Amasty Free Gift compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';

import { getRemainingGiftsQty } from '../util/Amasty/AmastyPromo';

export const AmastyFreeGiftContext = createContext({
    giftsLeft: 0,
    setGiftsLeft: () => {},
    selectedItems: [],
    setSelectedItems: () => {},
    handleSelectItem: () => {},
    handleRemoveItem: () => {},
    handleChangeItemQty: () => {}
});

AmastyFreeGiftContext.displayName = 'AmastyFreeGiftContext';

/** @namespace AmastyFreeGift/Context/AmastyFreeGift/AmastyFreeGiftProvider */
export const AmastyFreeGiftProvider = ({ children }) => {
    const [giftsLeft, setGiftsLeft] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const amAdd = useSelector((state) => state.ConfigReducer?.amAdd);
    const amItems = useSelector((state) => state.ConfigReducer?.amItems);

    const {
        commonQty
    } = amAdd;

    const handleSelectItem = (sku, options) => {
        setSelectedItems([
            ...selectedItems,
            {
                sku,
                giftQty: 1,
                options
            }
        ]);
    };

    const handleRemoveItem = (sku) => {
        const newSelectedItems = selectedItems.filter(({ sku: itemSku }) => itemSku !== sku);

        setSelectedItems(newSelectedItems);
    };

    const handleChangeItemQty = (sku, giftQty) => {
        const newSelectedItems = selectedItems.map((item) => {
            const { sku: itemSku } = item;

            if (itemSku !== sku) {
                return item;
            }

            return {
                ...item,
                giftQty
            };
        });

        setSelectedItems(newSelectedItems);
    };

    useEffect(() => {
        const newGiftsLeft = getRemainingGiftsQty(commonQty, selectedItems);

        setGiftsLeft(newGiftsLeft);
    }, [commonQty, selectedItems]);

    useEffect(() => {
        setGiftsLeft(commonQty);
        setSelectedItems([]);
    }, [amAdd, amItems]);

    return (
        <AmastyFreeGiftContext.Provider
          value={ {
              giftsLeft,
              setGiftsLeft,
              selectedItems,
              setSelectedItems,
              handleSelectItem,
              handleRemoveItem,
              handleChangeItemQty
          } }
        >
            { children }
        </AmastyFreeGiftContext.Provider>
    );
};

AmastyFreeGiftProvider.displayName = 'AmastyFreeGiftProvider';

AmastyFreeGiftProvider.propTypes = {
    children: ChildrenType.isRequired
};
