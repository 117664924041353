/**
 * @category  Scandiweb
 * @package   ScandiPWA/AdenAnais
 * @author    Anastasija Zubkova <info@scandiweb.com>
 */

import React, { PureComponent } from 'react';

import { AccordionType } from '../../type/Accordion';
import Accordion from './Accordion.component';

/** @namespace PageBuilder/Component/Accordion/Container/AccordionContainer */
export class AccordionContainer extends PureComponent {
    static propTypes = {
        elements: AccordionType.isRequired
    };

    containerProps() {
        const {
            elements: {
                BaseAccordion: {
                    propsBag: [{
                        'data-accordion-desktop-disabled': isDesktopDisabled = 0
                    }] = []
                },
                ExpandableContent: {
                    childEleBag: [
                        title = '',
                        content = {}
                    ] = [],
                    Ele = {}
                }
            }
        } = this.props;

        return {
            title,
            content,
            Ele,
            isDesktopDisabled
        };
    }

    render() {
        return (
            <Accordion
              { ...this.containerProps() }
            />
        );
    }
}

export default AccordionContainer;
