/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { HEADER2_NAME, PARAGRAPH_NAME } from '../AMSSteps/AMSSteps.config';

import './AMSTitleAndDescription.style';

/** @namespace PageBuilder/Component/AMSTitleAndDescription/Component/AMSTitleAndDescriptionComponent */
export class AMSTitleAndDescriptionComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        setIsExpanded: PropTypes.func.isRequired
    };

    renderExpandButton() {
        const { isExpanded, setIsExpanded } = this.props;

        return (
            <button
              key="expand"
              block="AMSTitleAndDescription"
              elem="Button"
              mods={ { type: 'expand', isExpanded } }
              onClick={ setIsExpanded }
              aria-label={ __('Expand description') }
            />
        );
    }

    renderDescription(content, isExpanded) {
        if (isExpanded) {
            return (
              <div
                block="AMSTitleAndDescription"
                elem="BodyExpanded"
              >
                { domToReact([content], this.parserOptions) }
              </div>
            );
        }

        return (
          <div
            block="AMSTitleAndDescription"
            elem="Body"
          >
            { domToReact([content], this.parserOptions) }
          </div>
        );
    }

    render() {
        const {
            element: { children }, isMobile, isExpanded
        } = this.props;
        const title = children.find(({ name }) => name && name === HEADER2_NAME);
        const content = children.find(({ name }) => name === PARAGRAPH_NAME);

        return (
            <div
              block="AMSTitleAndDescription"
              elem="TitleAndDescription"
            >
                <div
                  block="AMSTitleAndDescription"
                  elem="Head"
                >
                { domToReact([title], this.parserOptions) }
                { isMobile && this.renderExpandButton() }
                </div>
                { this.renderDescription(content, isExpanded) }
            </div>
        );
    }
}

export default AMSTitleAndDescriptionComponent;
