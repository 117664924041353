import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_ATTRAQT_SEARCH_RESULT_VIEW
} from '../component/GoogleTagManager/GoogleTagManager.events';

const state = (original) => ({
    ...original,
    isFired: false
});

const renderItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: {
            pathname
        },
        isLoading
    } = instance.props;

    const { isFired } = instance.state;
    const isSearch = pathname.includes(SEARCH);

    if (isSearch && !isFired && !isLoading) {
        instance.setState({ isFired: true });
        const timeout = setTimeout(() => {
            event(EVENT_GTM_IMPRESSIONS_SEARCH, {
                products: items,
                list: SEARCH,
                search: pathname.split(ROOT).pop()
            });

            clearTimeout(timeout);
        }, 1);
    }

    return callback(...args);
};

const renderSearchViewEvent = (args, callback, instance) => {
    const { event } = instance.props;

    const [searchTerm, metaId, pathToPage] = args;

    const timeout = setTimeout(() => {
        event(EVENT_GTM_ATTRAQT_SEARCH_RESULT_VIEW, {
            searchTerm, metaId, pathToPage
        });

        clearTimeout(timeout);
    }, 1);
};

const renderPageItems = (args, callback, instance) => {
    const {
        items,
        event,
        mix: {
            block = ''
        },
        location: {
            pathname,
            search
        }
    } = instance.props;
    const { isFired } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (!isSearch) {
        if (!(Object.keys(items).length === 0) && !isFired) {
            instance.setState({ isFired: true });
            const timeout = setTimeout(() => {
                event(EVENT_GTM_IMPRESSIONS_PLP, {
                    products: items, list: block, pathname, search
                });

                clearTimeout(timeout);
            }, 1);
        }
    }

    return callback(...args);
};

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPageItems,
            renderItems,
            renderSearchViewEvent
        },
        'member-property': {
            state
        }
    }
};
