/**
 * @category  Scandiweb
 * @package   ScandiPWA/PageBuilder
 * @author    Ramona Cunska <info@scandiweb.com>
 */

import '../style/_module.scss';

export class RouterComponent {
    /**
     * Extended to add body ID "html-body" to all pages.
     * This is required by the Dynamic CSS selector of Magento 2 page-builder.
     * Ref: https://devdocs.magento.com/page-builder/docs/styles/introduction.html#dynamic-css-selectors
     */
    componentDidMount = (args, callback, instance) => {
        document.querySelector('body').setAttribute('id', 'html-body');
        callback.apply(instance, args);
    };
}

const { componentDidMount } = new RouterComponent();

export default {
    'Component/Router/Component': {
        'member-function': {
            componentDidMount
        }
    }
};
