import Html from 'SourceComponent/Html';
import DataContainer from 'Util/Request/DataContainer';

import HtmlCode from './HtmlCode.component';

/** @namespace PageBuilder/Component/HtmlCode/Container/HtmlCodeContainer */
export class HtmlCodeContainer extends DataContainer {
    containerProps = () => {
        const { element } = this.state;
        const {
            elements: {
                BaseHtmlCode
            } = {}
        } = this.props;

        return {
            element,
            elements: {
                BaseHtmlCode
            }
        };
    };

    containerFunctions = {
        checkIfWidget: this.checkIfWidget.bind(this)
    };

    state = {
        element: []
    };

    componentDidMount() {
        this.checkIfWidget();
    }

    checkIfWidget() {
        const {
            elements: {
                BaseHtmlCode
            } = {}
        } = this.props;

        const allElements = [];

        if (Array.isArray(BaseHtmlCode.childEleBag[0])) {
            BaseHtmlCode.childEleBag[0].forEach((htmlElement) => {
                if (typeof htmlElement === 'object') {
                    allElements.push(<div>{ htmlElement }</div>);
                } else {
                    allElements.push(<Html content={ htmlElement } />);
                }
            });
        }

        if (!Array.isArray(BaseHtmlCode.childEleBag[0])) {
            if (typeof BaseHtmlCode.childEleBag[0] === 'object') {
                allElements.push(<div>{ BaseHtmlCode.childEleBag[0] }</div>);
            } else {
                allElements.push(<Html content={ BaseHtmlCode.childEleBag[0] } />);
            }
        }

        this.setState({
            element: allElements
        });
    }

    render() {
        return (
            <HtmlCode
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default HtmlCodeContainer;
