import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';

import { NO_MATCH_IDENTIFIER } from './NoMatch.config';

/** @namespace Scandipwa/Route/NoMatch/Component/NoMatchComponent */
export class NoMatchComponent extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <CmsBlock identifier={ NO_MATCH_IDENTIFIER } />
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatchComponent;
