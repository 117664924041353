import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Tab from './Tab.component';

/** @namespace PageBuilder/Component/Tab/Container/TabContainer */
export class TabContainer extends PureComponent {
    containerFunctions = {
        onClickTabMenu: this.onClickTabMenu.bind(this)
    };

    static propTypes = {
        elements: PropTypes.string.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            activeIdx: props.elements.BaseTabs.propsBag[0]['data-active-tab']
        };
    }

    containerProps = () => {
        const { activeIdx } = this.state;

        const {
            elements: {
                BaseTabs,
                TabMenu,
                TabMenuHeader,
                TabMenuLink,
                TabMenuTitle,
                TabContent,
                TabItem
            } = {}
        } = this.props;

        return {
            activeIdx,
            elements: {
                BaseTabs,
                TabMenu,
                TabMenuHeader,
                TabMenuLink,
                TabMenuTitle,
                TabContent,
                TabItem
            }
        };
    };

    onClickTabMenu(e, idx) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ activeIdx: idx });
    }

    render() {
        return (
            <Tab
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default TabContainer;
