/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable */

import {
    CheckoutAddressForm as SourceCheckoutAddressForm
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.component';

import { CheckoutAddressForm as form } from './CheckoutAddressForm.form';

import './CheckoutAddressForm.style.scss';

/** @namespace Scandipwa/Component/CheckoutAddressForm/Component/CheckoutAddressFormComponent */
export class CheckoutAddressFormComponent extends SourceCheckoutAddressForm {
    get map() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
            id
        } = this.props;

        return form({
            id,
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            ...address
        }, {
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange
        });
    }

    get fieldMap() {
        const fieldMap = this.map;

        const addressGroup = fieldMap.find(({ name }) => name === 'addressGroup');

        if (addressGroup) {
            addressGroup.events = {
                // Updates shipping methods on address blurt
                onBlur: super.onAddressChange,
                // Updates shipping methods on start
                onLoad: super.onAddressChange
            };
        }

        fieldMap.splice(0, 2);

        return fieldMap;
    }

    render() {
        const rendered = super.render();

        // eslint-disable-next-line no-magic-numbers
        setTimeout(() => this.updateFields(this.map), 100);

        return rendered;
    }

    updateFields(fields) {
        const { loqate: { loqate_update, suggestions }, isManualAddress } = this.props;

        const singleClick = loqate_update && suggestions && suggestions.length === 0;

        let show = false;

        const postcodeField = document.querySelector(`[name="${ this.makeInputName('postcode') }"]`);
        const postcodeValue = postcodeField?.value;

        if ((singleClick && postcodeValue) || isManualAddress) {
            show = true;
        }

        this.toggleFieldVisibility(fields, show);

        return fields;
    }

    toggleFieldVisibility(fields, show = false) {
        fields.forEach((field) => {
            const inputName = field.attr?.name;
            if (!inputName) {
                this.toggleFieldVisibility(field.fields, show);
                return;
            }

            this.addExpanded(inputName, show);

            if (inputName === this.makeInputName('postcode')) {
                return;
            }

            const input = document.querySelector(`[name="${ inputName }"]`);

            if (!input) {
                return;
            }

            const closestFieldWrapper = input.closest('.Field-Wrapper');

            if (show) {
                closestFieldWrapper.classList.remove('CheckoutAddressFormField__isHidden');
            } else {
                closestFieldWrapper.classList.add('CheckoutAddressFormField__isHidden');
            }
        });
    }

    addExpanded(inputName, show) {
        const input = document.querySelector(`[name="${ inputName }"]`);

        if (!input) {
            return;
        }

        const closestFieldWrapper = input.closest('.Field-Wrapper');

        if (show) {
            closestFieldWrapper.classList.add('CheckoutAddressFormField__isExpanded');
        }
    }

    makeInputName(name) {
        return `${ this.props.id }_${ name }`;
    }
}

export default CheckoutAddressFormComponent;
