/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

export const StoreFinder = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "storefinder" */
    '../route/StoreFinder'
));

export class RouterPlugin {
    beforeSwitchItemsType = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/salon-locator') } exact component={ StoreFinder } />,
            position: 90
        }

    ];
}

const {
    beforeSwitchItemsType
} = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: [
                {
                    position: 100,
                    implementation: beforeSwitchItemsType
                }
            ]
        }
    }
};

export default config;
