import {
    EVENT_GTM_PRODUCT_ADD_TO_CART
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const aroundAfterOnCheckoutButtonClick = (args, callback, instance) => {
    const {
        selectedGift,
        selectedGift: {
            variants = [], categories, sku
        } = {}
    } = instance.state;

    if (sku) {
        const { event } = instance.props;

        const configurableVariantIndex = Object.keys(variants).find((i) => variants[i]?.sku === sku);

        const productToAdd = variants?.length
            ? { ...selectedGift, configurableVariantIndex }
            : selectedGift;

        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: productToAdd,
            quantity: 1,
            configurableVariantIndex,
            categories,
            isGift: true
        });
    }

    return callback(...args);
};
export default {
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Route/CartPage/Container': {
        'member-function': {
            onCheckoutButtonClick: {
                position: 120,
                implementation: aroundAfterOnCheckoutButtonClick
            }
        }
    }
};
