/**
 * Checkout.com package for SPWA for card payments
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import React, { useState } from 'react';

import { ChildrenType } from 'Type/Common.type';

export const CheckoutComCardContext = React.createContext(
    {
        isTokenizationInProgress: false,
        toggleTokenizationInProgress: () => {
        }
    }
);

CheckoutComCardContext.displayName = 'CheckoutComCardContext';

/** @namespace CheckoutComCard/Context/CheckoutComCard/CheckoutComCardProvider */
export const CheckoutComCardProvider = ({ children }) => {
    const [isTokenizationInProgress, setIsTokenizationInProgress] = useState(false);

    const toggleTokenizationInProgress = (isInProgress) => setIsTokenizationInProgress(isInProgress);

    const value = {
        isTokenizationInProgress,
        toggleTokenizationInProgress
    };

    return (
        <CheckoutComCardContext.Provider value={ value }>
            { children }
        </CheckoutComCardContext.Provider>
    );
};

CheckoutComCardProvider.displayName = 'CheckoutComCardProvider';

CheckoutComCardProvider.propTypes = {
    children: ChildrenType.isRequired
};
