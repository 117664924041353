/* eslint-disable max-lines */
/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */
import PropTypes from 'prop-types';
import { lazy, PureComponent } from 'react';

import history from 'Util/History';

import './AMSProductDetails.style';

export const ProductPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage')
);

/** @namespace PageBuilder/Component/AMSProductDetails/Component/AMSProductDetailsComponent */
export class AMSProductDetailsComponent extends PureComponent {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
    };

    render() {
        const { sku, id } = this.props;

        return (
            <div
              block="AMSProductDetails"
              elem="Wrapper"
            >
                <ProductPage
                  history={ history }
                  productSKU={ sku }
                  isAMSPage
                  productID={ id }
                />
            </div>
        );
    }
}

export default AMSProductDetailsComponent;
