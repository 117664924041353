/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/sort-comp */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';
import { ChildrenType } from 'Type/Common.type';

import { PRIVACY_POLICY_POPUP_ID } from '../PrivacyPolicyPopup/PrivacyPolicyPopup.config';

import './PrivacyPolicyButton.style.scss';

/** @namespace Gdpr/Component/PrivacyPolicyButton/Component/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Gdpr/Component/PrivacyPolicyButton/Component/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(PRIVACY_POLICY_POPUP_ID, payload))
});

/** @namespace Gdpr/Component/PrivacyPolicyButton/Component/PrivacyPolicyButtonComponent */
export class PrivacyPolicyButtonComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    render() {
        const { children } = this.props;

        return (
            <button
              block="Button"
              mods={ { likeLink: true } }
              mix={ { block: 'PrivacyPolicyButton' } }
              onClick={ (e) => this.onClick(e) }
            >
                { children }
            </button>
        );
    }

    onClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const { showPopup, privacyPolicy } = this.props;

        /* super-ugly workaround :|
        Issue: without timeout, the popup is shown immediately, then the click is somehow captured by ClickOutside
         wrappers, which are meant to close the current popup when the user clicks outside, and the popup is
          immediately hidden.
         */
        setTimeout(() => {
            showPopup({ privacyPolicy });
        }, 42);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyButtonComponent);
