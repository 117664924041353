/* eslint-disable react/forbid-prop-types */
/**
 * @category  Scandiweb
 * @package   ScandiPWA/AdenAnais
 * @author    Anastasija Zubkova <info@scandiweb.com>
 */

import { PropTypes } from 'prop-types';

export const PageBuilderSlideType = PropTypes.shape({
    Ele: PropTypes.object,
    propsBag: PropTypes.arrayOf(PropTypes.object),
    childData: PropTypes.arrayOf(PropTypes.object),
    childEleBag: PropTypes.arrayOf(PropTypes.object)
});

export const PageBuilderSliderElementsType = PropTypes.shape({
    BaseSlider: PageBuilderSlideType,
    Slide: PageBuilderSlideType
});
