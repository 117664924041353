/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import AMSTitleAndDescriptionComponent from './AMSTitleAndDescription.component';

/** @namespace PageBuilder/Component/AMSTitleAndDescription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace PageBuilder/Component/AMSTitleAndDescription/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace PageBuilder/Component/AMSTitleAndDescription/Container/AMSTitleAndDescriptionContainer */
export class AMSTitleAndDescriptionContainer extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        element: PropTypes.object.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        setIsExpanded: this.setIsExpanded.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { isMobile } = this.props;
        const { isMobile: prevIsMobile } = prevProps;

        if (isMobile !== prevIsMobile) {
            this.setState((prevState) => ({ ...prevState, isExpanded: !isMobile }));
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isExpanded: !props.isMobile
        };
    }

    containerProps() {
        const { element, isMobile } = this.props;
        const { isExpanded } = this.state;

        return {
            element,
            isMobile,
            isExpanded
        };
    }

    setIsExpanded() {
        this.setState((prevState) => ({ ...prevState, isExpanded: !prevState.isExpanded }));
    }

    render() {
        return (
            <AMSTitleAndDescriptionComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AMSTitleAndDescriptionContainer);
