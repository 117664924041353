/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/zendesk
 * @author  Jort Geurts <info@scandiweb.com>
 * @author  Fuad Kamilov <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { toggleScroll } from 'Util/Browser';

import { ZENDESK_SCRIPT_ID } from './ZendeskLiveChat.config';

import './ZendeskLiveChat.style.scss';

/** @namespace Zendesk/Component/ZendeskLiveChat/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    zendesk: state.ConfigReducer.zendesk,
    currentNavigationState: state.NavigationReducer.TOP_NAVIGATION_TYPE.navigationState.name
});

/** @namespace Zendesk/Component/ZendeskLiveChat/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Zendesk/Component/ZendeskLiveChat/Container/ZendeskLiveChatContainer */
export class ZendeskLiveChatContainer extends PureComponent {
    static propTypes = {
        zendesk: PropTypes.shape({
            zendesk_enabled: PropTypes.bool,
            zendesk_url: PropTypes.string,
            zendesk_enabled_urls: PropTypes.string
        }).isRequired
    };

    componentDidMount() {
        if (window.hasLoaded) {
            this.injectZendeskScripts();

            if (this.liveChatInitialized()) {
                window.zE('messenger', 'show');
            }

            return;
        }

        // Reduce Zendesk impact on main thread perfomance
        window.addEventListener('load', () => {
            this.injectZendeskScripts();
            window.hasLoaded = true;
        });
    }

    // eslint-disable-next-line consistent-return
    componentDidUpdate() {
        const {
            zendesk: {
                zendesk_enabled_urls = null,
                zendesk_enabled = false
            } = {}
        } = this.props;

        this.injectZendeskScripts();

        const currentURL = window.location.href;
        const cartString = 'cart';
        const checkoutString = 'checkout';
        const chatInitialized = this.liveChatInitialized();

        const isCartOrCheckout = currentURL.includes(cartString) || currentURL.includes(checkoutString);
        const isNotEnabledUrl = !zendesk_enabled
        && !zendesk_enabled_urls?.split(',')?.find((item) => currentURL?.includes(item?.trim()));

        if ((isNotEnabledUrl || isCartOrCheckout) && chatInitialized) {
            window.zE('messenger', 'hide');
            return null;
        }

        if (chatInitialized) {
            window.zE('messenger', 'show');
        }

        const script = document.getElementById(ZENDESK_SCRIPT_ID);

        if ((isNotEnabledUrl || isCartOrCheckout) && script) {
            this.removeZendeskScripts();
        }
    }

    componentWillUnmount() {
        this.removeZendeskScripts();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            isConfigured: false
        };
    }

    liveChatInitialized() {
        return window.zE;
    }

    injectZendeskScripts() {
        const { zendesk } = this.props;

        if (!zendesk) {
            return;
        }

        const {
            zendesk_enabled,
            zendesk_url,
            zendesk_enabled_urls
        } = zendesk;

        const currentURL = window.location.href;

        if (zendesk_enabled && zendesk_url && !document.getElementById(ZENDESK_SCRIPT_ID)) {
            const script = document.createElement('script');
            script.src = zendesk_url;
            script.id = ZENDESK_SCRIPT_ID;

            document.head.insertBefore(script, document.head.childNodes[0]);
        }
        const isEnabledUrl = zendesk_enabled_urls?.split(',')?.find((item) => currentURL?.includes(item?.trim()));

        if (zendesk_url && !document.getElementById(ZENDESK_SCRIPT_ID) && isEnabledUrl) {
            const script = document.createElement('script');
            script.src = zendesk_url;
            script.id = ZENDESK_SCRIPT_ID;

            document.head.insertBefore(script, document.head.childNodes[0]);
        }
    }

    removeZendeskScripts() {
        const script = document.getElementById(ZENDESK_SCRIPT_ID);
        if (script) {
            script.remove();
        }
    }

    freezeScroll() {
        toggleScroll();

        // Fix scrolling on iOS
        document.documentElement.style.overflow = 'hidden';
        document.documentElement.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.overscrollBehaviorY = 'contain';
    }

    unfreezeScroll() {
        toggleScroll();

        // Fix scrolling on iOS
        document.documentElement.style.overflow = null;
        document.body.style.overflow = null;
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('overscrollBehaviorY');
    }

    render() {
        return null;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZendeskLiveChatContainer));
