/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @author Scandiweb info@scandiweb.com>
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';

/** @namespace Scandipwa/Route/CheckoutComThreeDs/Component/CheckoutComThreeDsComponent */
export class CheckoutComThreeDsComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        turnOffBreadcrumbs: PropTypes.func.isRequired
    };

    renderLoader() {
        const { isLoading } = this.props;

        return (
            <Loader isLoading={ isLoading } />
        );
    }

    render() {
        const { turnOffBreadcrumbs } = this.props;
        turnOffBreadcrumbs();

        return (
            <ContentWrapper label="CheckoutComThreeDS">
                <div block="CheckoutComThreeDs">
                    { this.renderLoader() }
                </div>
            </ContentWrapper>
        );
    }
}

export default withRouter(CheckoutComThreeDsComponent);
